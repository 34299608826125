import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSupabaseClient } from '../context/supabaseContext';
import { useAuth, useUser } from '@clerk/clerk-react';
import { API_URL } from '../config';

interface GoogleContextType {
  isGoogleConnected: boolean;
  connectedEmail: string | null;
  setConnectedEmail: (value: string | null) => void;
  handleConnectGoogle: () => Promise<void>;
  handleDisconnectGoogle: () => Promise<void>;
  googleAnalyticsProperties: Array<{ name: string, displayName: string }>;
  googleAnalyticsPropertyId: string;
  currentGAProperty: string | null;
  isLoadingProperties: boolean;
  isGoogleSettingsAlertOpen: boolean;
  setIsGoogleSettingsAlertOpen: (isOpen: boolean) => void;
  setGoogleAnalyticsPropertyId: (id: string) => void;
  handleOpenGoogleSettings: () => Promise<void>;
  confirmGoogleSettings: () => Promise<void>;
}

const GoogleContext = createContext<GoogleContextType | undefined>(undefined);

export const GoogleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = useSupabaseClient();
  const { getToken } = useAuth();
  const { user } = useUser();

  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState<string | null>(null);
  const [googleAnalyticsProperties, setGoogleAnalyticsProperties] = useState<Array<{ name: string, displayName: string }>>([]);
  const [googleAnalyticsPropertyId, setGoogleAnalyticsPropertyId] = useState('');
  const [currentGAProperty, setCurrentGAProperty] = useState<string | null>(null);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [isGoogleSettingsAlertOpen, setIsGoogleSettingsAlertOpen] = useState(false);

  const handleConnectGoogle = async () => {
    try {
      const token = await getToken({ template: 'supabase' });
      if (!token) {
        throw new Error('No authorization token found');
      }

      console.log('Making request to:', `${API_URL}/google-authorize`);
      
      const response = await fetch(`${API_URL}/google-authorize`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to start Google authentication: ${errorText}`);
      }

      const data = await response.json();
      
      if (!data.authorization_url) {
        console.error('Response data:', data);
        throw new Error('No authorization URL in response');
      }

      console.log('About to redirect to:', data.authorization_url);
      
      window.location.href = data.authorization_url;

    } catch (error) {
      console.error('Error connecting to Google account:', error);
      throw error;
    }
  };

  const handleDisconnectGoogle = async () => {
    try {
      const response = await fetch(`${API_URL}/google-disconnect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getToken({ template: 'supabase' })}`,
          'ngrok-skip-browser-warning': 'true'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to disconnect Google account');
      }

      setConnectedEmail(null);
      setIsGoogleConnected(false);
    } catch (error) {
      console.error('Error disconnecting Google account', error);
      throw error;
    }
  };

  const handleOpenGoogleSettings = async () => {
    setIsGoogleSettingsAlertOpen(true);
    setIsLoadingProperties(true);
    
    try {
      if (!isGoogleConnected) {
        setGoogleAnalyticsProperties([]);
        setGoogleAnalyticsPropertyId('none');
        setIsLoadingProperties(false);
        return;
      }

      await fetchCurrentGAProperty();

      const { data: authData, error: authError } = await client
        .from('gmail_auth')
        .select('access_token, refresh_token, expires_at')
        .eq('user_id', user?.id)
        .single();

      console.log('Auth data:', { 
        hasAccessToken: !!authData?.access_token,
        hasRefreshToken: !!authData?.refresh_token,
        error: authError
      });

      if (authError) {
        console.error('Error fetching Google credentials:', authError);
        throw new Error('Failed to fetch Google credentials');
      }

      if (!authData?.access_token || !authData?.refresh_token) {
        throw new Error('Missing Google credentials');
      }

      const response = await fetch(`${API_URL}/api/google-analytics/properties`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getToken({ template: "supabase" })}`,
          'ngrok-skip-browser-warning': 'true'
        },
        body: JSON.stringify({
          access_token: authData.access_token,
          refresh_token: authData.refresh_token,
          expires_at: authData.expires_at,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response from backend:', errorText);
        throw new Error(`Failed to fetch Google Analytics properties: ${errorText}`);
      }

      const data = await response.json();
      
      if (!data || !Array.isArray(data.properties)) {
        throw new Error('Invalid response format from Google Analytics API');
      }

      setGoogleAnalyticsProperties(data.properties);

      if (data.properties.length === 0) {
        console.log('No Google Analytics properties found for this account');
        setGoogleAnalyticsPropertyId('none');
      } else if (currentGAProperty) {
        const selectedProperty = data.properties.find(
          (prop: any) => prop.name.split('/').pop() === currentGAProperty
        );
        if (selectedProperty) {
          setGoogleAnalyticsPropertyId(selectedProperty.name);
        } else {
          setGoogleAnalyticsPropertyId(data.properties[0].name);
        }
      } else {
        setGoogleAnalyticsPropertyId(data.properties[0].name);
      }
    } catch (error) {
      console.error('Error fetching Google Analytics properties:', error);
      setGoogleAnalyticsProperties([]);
      setGoogleAnalyticsPropertyId('none');
      throw error;
    } finally {
      setIsLoadingProperties(false);
    }
  };

  const confirmGoogleSettings = async () => {
    try {
      const propertyId = googleAnalyticsPropertyId === 'none' ? null : googleAnalyticsPropertyId.split('/').pop();

      const { error } = await client
        .from('user_settings')
        .upsert({
          user_id: user?.id,
          source_ga_property: propertyId
        }, {
          onConflict: 'user_id'
        });

      if (error) throw error;
      return Promise.resolve();
    } catch (error) {
      console.error('Error saving Google settings:', error);
      throw error;
    }
  };

  const fetchCurrentGAProperty = async () => {
    try {
      const { data, error } = await client
        .from('user_settings')
        .select('source_ga_property')
        .eq('user_id', user?.id)
        .single();

      if (error) throw error;
      setCurrentGAProperty(data?.source_ga_property || null);
    } catch (error) {
      console.error('Error fetching current GA property:', error);
    }
  };

  // Check Google connection
  useEffect(() => {
    const checkGoogleConnection = async () => {
      try {
        const { data: gmailData, error: gmailError } = await client
          .from('gmail_auth')
          .select('*')
          .single();

        if (gmailError) {
          if (gmailError.code !== 'PGRST116') {
            console.error('Error checking Google connection:', gmailError);
          }
          setConnectedEmail(null);
          setIsGoogleConnected(false);
          return;
        }

        setConnectedEmail(gmailData?.email || null);
        setIsGoogleConnected(!!gmailData);
      } catch (error) {
        console.error('Error checking Google connection:', error);
        setConnectedEmail(null);
        setIsGoogleConnected(false);
      }
    };

    checkGoogleConnection();
  }, [client]);

  const value = {
    isGoogleConnected,
    connectedEmail,
    setConnectedEmail,
    handleConnectGoogle,
    handleDisconnectGoogle,
    googleAnalyticsProperties,
    googleAnalyticsPropertyId,
    currentGAProperty,
    isLoadingProperties,
    isGoogleSettingsAlertOpen,
    setIsGoogleSettingsAlertOpen,
    setGoogleAnalyticsPropertyId,
    handleOpenGoogleSettings,
    confirmGoogleSettings,
  };

  return (
    <GoogleContext.Provider value={value}>
      {children}
    </GoogleContext.Provider>
  );
};

export const useGoogle = () => {
  const context = useContext(GoogleContext);
  if (context === undefined) {
    throw new Error('useGoogle must be used within a GoogleProvider');
  }
  return context;
};
