import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Settings from './pages/settings';
import Briefs from './pages/briefs';
import Login from './pages/login';
import SignUp from './pages/signup';
import Landing from './pages/landing';
import { SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";
import { SupabaseProvider } from './context/supabaseContext';
import { SlackProvider } from './context/slackContext';
import { XeroProvider } from './context/xeroContext';
import { HubspotProvider } from './context/hubspotContext';
import { ShopifyProvider } from './context/shopifyContext';
import { GoogleProvider } from './context/googleContext';
import ActionPanePreview from './pages/actions';
import { EmailActions } from './pages/action-panes/inbox';
import EmailViewer from './pages/briefs';
import analytics from './analytics';
import MuteSuccess from './pages/mute-success';
import { useUser } from '@clerk/clerk-react';

// Create a separate component for the routes that needs useLocation
const AppRoutes: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    // Track specific email click event if coming from email
    if (searchParams.get('utm_source') === 'email') {
      analytics.track('email_link_click', {
        path: location.pathname,
        brief_id: location.pathname.split('-')[2],
        thread_id: location.pathname.split('-')[1],
        utm_medium: searchParams.get('utm_medium')
      });
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/sign-up" element={<Navigate to="/signup" replace />} />
      <Route path="/landing" element={<Landing />} />
      <Route path="/settings" element={
        <>
          <SignedIn>
            <Settings />
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn redirectUrl={location.pathname} />
          </SignedOut>
        </>
      } />
      <Route path="/briefs/:id" element={
        <>
          <SignedIn>
            <Briefs />
        </SignedIn>
          <SignedOut>
            <RedirectToSignIn redirectUrl={location.pathname} />
          </SignedOut>
        </>
      } />
      <Route path="/briefs" element={
        <>
          <SignedIn>
            <Briefs />
        </SignedIn>
          <SignedOut>
            <RedirectToSignIn redirectUrl={location.pathname} />
          </SignedOut>
        </>
      } />
      <Route path="/actions/:paneId" element={
        <>
          <SignedIn>
            <ActionPanePreview />
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn redirectUrl={location.pathname} />
          </SignedOut>
        </>
      } />
      <Route path="/actions" element={
        <>
          <SignedIn>
            <Navigate to="/actions/default" replace />
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn redirectUrl={location.pathname} />
          </SignedOut>
        </>
      } />
      <Route path="/" element={
        <>
          <SignedIn>
            <Navigate to="/briefs" replace />
          </SignedIn>
          <SignedOut>
            <Navigate to="/login" replace />
          </SignedOut>
        </>
      } />
      <Route path="/mute-success" element={<MuteSuccess />} />
    </Routes>
  );
};

const App: React.FC = () => {
  const { user } = useUser();
  
  useEffect(() => {
    analytics.setUserId(user?.id || null);
  }, [user?.id]);

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
      <Router>
        <SupabaseProvider>
          <GoogleProvider>
            <SlackProvider>
              <XeroProvider>
                <HubspotProvider>
                  <ShopifyProvider>
                    <AppRoutes />
                  </ShopifyProvider>
                </HubspotProvider>
              </XeroProvider>
            </SlackProvider>
          </GoogleProvider>
        </SupabaseProvider>
      </Router>
    </div>
  );
};

export default App;
