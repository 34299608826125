import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { OrganizationSwitcher, UserButton, SignOutButton, useUser, useClerk, useOrganization } from '@clerk/clerk-react';
import { Heading } from './heading';
import clsx from 'clsx';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useSupabaseClient } from '../context/supabaseContext';
import { Button } from './button';
import { useGoogle } from '../context/googleContext';
import analytics from '../analytics';

interface TopNavigationProps {
  hasOrganization?: boolean;
}

const TopNavigation: React.FC<TopNavigationProps> = ({ hasOrganization = false }) => {
  const location = useLocation();
  const { user } = useUser();
  const { openUserProfile, openOrganizationProfile } = useClerk();
  const { organization } = useOrganization();
  const [isOrgHovered, setIsOrgHovered] = useState(false);
  const [isUserHovered, setIsUserHovered] = useState(false);
  const [needsGoogleReconnect, setNeedsGoogleReconnect] = useState(false);
  const supabase = useSupabaseClient();
  const { isGoogleConnected, handleConnectGoogle } = useGoogle();

  useEffect(() => {
    const checkGoogleAuth = async () => {
      if (!user) return;
      
      // First check if user has ever connected Google
      const { data: userSettings } = await supabase
        .from('user_settings')
        .select('has_ever_connected_google')
        .eq('user_id', user.id)
        .single();

      // Don't show banner if user has never connected Google
      if (!userSettings?.has_ever_connected_google) {
        setNeedsGoogleReconnect(false);
        return;
      }

      // Check if user has a gmail_auth row
      const { error } = await supabase
        .from('gmail_auth')
        .select('id')
        .eq('user_id', user.id)
        .single();

      // Show banner only if no gmail_auth row exists
      if (error?.code === 'PGRST116') {
        setNeedsGoogleReconnect(true);
      }
    };

    checkGoogleAuth();
  }, [user]);

  const handleGoogleReconnect = () => {
    analytics.track('data_source_action', {
      source: 'google',
      action: 'connect'
    });
    handleConnectGoogle();
  };

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex justify-between items-center w-full">
        {hasOrganization ? (
          <div 
            className={clsx(
              "rounded-lg transition-colors duration-200",
              isOrgHovered ? "bg-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800" : ""
            )}
            onMouseEnter={() => setIsOrgHovered(true)}
            onMouseLeave={() => setIsOrgHovered(false)}
          >
            <button
              onClick={() => openOrganizationProfile()}
              className="flex items-center gap-2 p-2"
            >
              <div className="w-6 h-6 rounded-full overflow-hidden bg-gray-100 flex items-center justify-center">
                {organization?.imageUrl ? (
                  <img 
                    src={organization.imageUrl} 
                    alt={organization.name || 'Organization'} 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" 
                    fill="currentColor" 
                    className="w-3 h-3 text-gray-600"
                  >
                    <path d="M4 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4Zm2 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Zm0 6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z" />
                  </svg>
                )}
              </div>
              <span className="text-sm font-medium text-zinc-950 dark:text-gray-400">
                {organization?.name || 'Select Organization'}
              </span>
            </button>
          </div>
        ) : (
          <Heading level={2} className="text-2xl font-semibold">Welcome</Heading>
        )}

        <div className="flex items-center space-x-8">
          <nav className="flex items-center space-x-4">
            <div className="hidden md:block">
              {needsGoogleReconnect && (
                <div className="inline-flex items-center border border-yellow-600 dark:border-yellow-400 rounded-lg px-3 py-2">
                  <ExclamationTriangleIcon className="h-5 w-5 flex-shrink-0 text-yellow-600 dark:text-yellow-400 mr-2" />
                  <span className="text-sm text-yellow-700 dark:text-yellow-300 mr-3">
                    Reconnect Google to analyze your inbox
                  </span>
                  <Button
                    onClick={handleGoogleReconnect}
                    color="yellow"
                    className="text-sm py-1"
                  >
                    Reconnect
                  </Button>
                </div>
              )}
            </div>
            <ul className="flex space-x-4">
              <li>
                <Link 
                  to="/briefs" 
                  className={clsx(
                    "text-sm",
                    location.pathname.startsWith('/briefs') 
                      ? "text-gray-900 dark:text-white font-medium" 
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300",
                    "no-underline hover:underline"
                  )}
                >
                  Workspace
                </Link>
              </li>
              <li>
                <Link 
                  to="/settings" 
                  className={clsx(
                    "text-sm",
                    location.pathname === '/settings' 
                      ? "text-gray-900 dark:text-white font-medium" 
                      : "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300",
                    "no-underline hover:underline"
                  )}
                >
                  Settings
                </Link>
              </li>
            </ul>
          </nav>

          <div 
            className={clsx(
              "rounded-lg transition-colors duration-200",
              isUserHovered ? "bg-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800" : ""
            )}
            onMouseEnter={() => setIsUserHovered(true)}
            onMouseLeave={() => setIsUserHovered(false)}
          >
            <div className="p-2 [&>*]:!w-6 [&>*]:!h-6 [&>*]:flex [&>*]:items-center [&>*]:justify-center flex items-center justify-center">
              <UserButton afterSignOutUrl="/" />
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden">
        {needsGoogleReconnect && (
          <div className="flex items-center justify-center border border-yellow-600 dark:border-yellow-400 rounded-lg px-3 py-2 mt-4">
            <ExclamationTriangleIcon className="h-5 w-5 flex-shrink-0 text-yellow-600 dark:text-yellow-400 mr-2" />
            <span className="text-sm text-yellow-700 dark:text-yellow-300 mr-3">
              Reconnect Google to analyze your inbox
            </span>
            <Button
              onClick={handleGoogleReconnect}
              color="yellow"
              className="text-sm py-1"
            >
              Reconnect
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavigation;