import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { API_URL } from '../../config';
import clsx from 'clsx';
import { Button } from '../../components/button';
import { useSupabaseClient } from '../../context/supabaseContext';
import { Heading } from '../../components/heading';
import { Link, useLocation } from 'react-router-dom';
import analytics from '../../analytics';
import { Spinner } from '../../components/spinner';
import { ChannelInfo, SlackMessage, Update } from '../../types/channel';

interface ChannelActionsProps {
  channelId: string;
  briefId?: string;
  initialChannelInfo?: ChannelInfo;
}

interface AssessmentData {
  title: string;
  description: string;
}

const parseSlackAssessment = (assessmentContent: string, channelId: string): AssessmentData | null => {
  try {
    const cleanContent = assessmentContent
      .replace(/^"|"$/g, '')
      .replace(/\\n/g, '\n')
      .replace(/\\"/g, '"')
      .replace(/\*\*/g, '');
    
    const rows = cleanContent.split('\n').filter(row => row.includes('|'));
    const dataRows = rows.slice(2);
    
    const cleanChannelId = channelId.trim().toLowerCase();
    const targetRow = dataRows.find(row => {
      const rowColumns = row.split('|');
      const rowChannelId = rowColumns[rowColumns.length - 2]?.replace(/\s+/g, '').toLowerCase();
      return rowChannelId === cleanChannelId;
    });
    
    if (!targetRow) return null;

    const columns = targetRow.split('|')
      .map(col => col.trim())
      .filter(Boolean);

    if (columns.length < 3) return null;

    return {
      title: columns[0],
      description: columns[1]
    };
  } catch (error) {
    console.error('Error parsing Slack assessment:', error);
    return null;
  }
};

// Add this helper function at the top level
const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }
  return 'unknown';
};

export const ChannelActions: React.FC<ChannelActionsProps> = ({
  channelId,
  briefId,
  initialChannelInfo
}) => {
  const { getToken } = useAuth();
  const [channelInfo, setChannelInfo] = useState<ChannelInfo | null>(initialChannelInfo || {
    highlights: '',
    channel_name: '',
    channel_id: '',
    messages: [],
    since: '',
    updates: { has_updates: false, updates: [] }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [slackAssessment, setSlackAssessment] = useState<AssessmentData | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [expandedThreads, setExpandedThreads] = useState<Set<string>>(new Set());
  const [workspaceId, setWorkspaceId] = useState<string | null>(null);
  
  const location = useLocation();
  const client = useSupabaseClient();
  const containerRef = useRef<HTMLDivElement>(null);

  // Show transition after loading
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
      if (window.innerWidth <= 768 && containerRef.current) {
        const yOffset = containerRef.current.getBoundingClientRect().top + window.scrollY - 20;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      }
    }, 100);
  }, []);

  // Fetch channel updates
  useEffect(() => {
    const fetchChannelUpdates = async () => {
      try {
        const token = await getToken({ template: "supabase" });
        const response = await fetch(`${API_URL}/api/canvas-slack-channel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            channel_id: channelId,
            brief_id: briefId
          })
        });

        const data = await response.json();
        console.log('Channel updates response:', data);
        setChannelInfo(data);
      } catch (error) {
        console.error('Error fetching channel updates:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChannelUpdates();
  }, [channelId, briefId, getToken]);

  // Fetch Slack assessment
  useEffect(() => {
    const fetchSlackAssessment = async () => {
      if (!briefId) return;

      try {
        const queryId = briefId.includes('brief-')
          ? briefId.split('brief-')[1]
          : briefId;

        const { data, error } = await client
          .from('daily_brief_input_data')
          .select('slack_assessment')
          .eq('id', queryId)
          .single();

        if (error) throw error;

        if (data?.slack_assessment) {
          const parsedData = parseSlackAssessment(data.slack_assessment, channelId);
          setSlackAssessment(parsedData);
        }
      } catch (error) {
        console.error('Error fetching Slack assessment:', error);
      }
    };

    fetchSlackAssessment();
  }, [briefId, channelId, client]);

  // Track when updates are viewed
  useEffect(() => {
    if (channelInfo?.updates?.has_updates) {
      analytics.track('channel_updates_viewed', {
        channel_id: channelId,
        brief_id: briefId,
        update_count: channelInfo.updates.updates.length
      });
    }
  }, [channelInfo?.updates, channelId, briefId]);

  // Add new useEffect to fetch workspace ID
  useEffect(() => {
    const fetchWorkspaceId = async () => {
      try {
        const { data, error } = await client
          .from('slack_credentials')
          .select('workspace_id')
          .single();

        if (error) throw error;
        if (data?.workspace_id) {
          setWorkspaceId(data.workspace_id);
        }
      } catch (error) {
        console.error('Error fetching workspace ID:', error);
      }
    };

    fetchWorkspaceId();
  }, [client]);

  const toggleThread = (timestamp: string) => {
    setExpandedThreads(prev => {
      const newSet = new Set(prev);
      if (newSet.has(timestamp)) {
        newSet.delete(timestamp);
      } else {
        newSet.add(timestamp);
        analytics.track('expand_thread', {
          channel_id: channelId,
          brief_id: briefId,
          timestamp
        });
      }
      return newSet;
    });
  };

  const handleSlackOpen = () => {
    const os = getMobileOperatingSystem();
    let appOpened = false;
    
    if (!workspaceId) {
      window.open('https://slack.com/', '_blank');
      return;
    }

    const deepLinks = {
      Android: `slack://team/${workspaceId}/channel/${channelId}`,
      iOS: `slack://team/${workspaceId}/channel/${channelId}`,
      unknown: `slack://channel?team=${workspaceId}&id=${channelId}`
    };

    const webFallback = `https://app.slack.com/client/${workspaceId}/${channelId}`;

    // Listen for app launch
    window.addEventListener('blur', () => {
      appOpened = true;
    }, { once: true });

    // Try desktop/mobile app first
    window.location.href = deepLinks[os];
    
    // Only fallback if app didn't open
    setTimeout(() => {
      if (!appOpened) {
        window.location.href = webFallback;
      }
    }, 3000);

    analytics.track('open_slack_clicked', {
      channel_id: channelInfo?.channel_id,
      brief_id: briefId
    });
  };

  const handleUpdateClick = (update: Update) => {
    analytics.track('channel_update_clicked', {
      channel_id: channelId,
      brief_id: briefId,
      update_type: update.type
    });
  };

  const handleMessageClick = (message: SlackMessage) => {
    analytics.track('channel_message_clicked', {
      channel_id: channelId,
      brief_id: briefId,
      has_thread: !!message.thread_messages?.length,
      thread_count: message.thread_messages?.length || 0,
      timestamp: message.timestamp
    });
  };

  return (
    <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-900">
      <div 
        ref={containerRef}
        className={clsx(
          "max-w-3xl mx-auto p-6 space-y-6 [&_*]:leading-normal [&_p]:m-0",
          "transition-all duration-500 ease-out",
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        )}
      >
        {/* Header */}
        <div className={clsx(
          "transition-all duration-500 delay-100",
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        )}>
          <div className="[&>h1]:!mb-0">
            <Heading level={1} className="text-2xl font-bold text-zinc-900 dark:text-white">
              {channelInfo?.channel_name || 'Channel Updates'}
            </Heading>
            {channelInfo?.highlights && (
              <p className="text-sm text-zinc-500 dark:text-gray-400">
                {channelInfo.highlights}
              </p>
            )}
          </div>
        </div>

        {/* Updates Section - only show when has_updates is true */}
        {channelInfo?.updates?.has_updates && (
          <section className={clsx(
            "bg-blue-50 dark:bg-gray-800 rounded-lg shadow-lg ring-1 ring-blue-100 dark:ring-gray-700 p-6",
            "transition-all duration-500 delay-200",
            isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
          )}>
            <div className="space-y-4">
              <div className="flex items-center gap-2 text-blue-600 dark:text-blue-400">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                  <path fillRule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clipRule="evenodd" />
                </svg>
                <span className="text-sm font-medium">New Updates</span>
              </div>

              {isLoading ? (
                <div className="flex justify-center py-2">
                  <Spinner className="w-4 h-4" />
                </div>
              ) : (
                <div className="text-sm text-zinc-700 dark:text-zinc-300">
                  {channelInfo.updates.updates.map((update, index) => (
                    <div 
                      key={index} 
                      onClick={() => handleUpdateClick(update)}
                      className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow-sm ring-1 ring-zinc-950/5 dark:ring-white/10 mb-4 last:mb-0 cursor-pointer"
                    >
                      <div className="flex items-center gap-2 mb-2">
                        <div className="inline-flex items-center gap-1.5 px-2.5 py-0.5 rounded-full text-xs font-medium bg-zinc-100 dark:bg-gray-700 text-zinc-700 dark:text-zinc-300">
                          {update.type === "resolution" && (
                            <svg className="w-3.5 h-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          )}
                          {update.type === "progress" && (
                            <svg className="w-3.5 h-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                          )}
                          {update.type === "change" && (
                            <svg className="w-3.5 h-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                          )}
                          {update.type === "clarification" && (
                            <svg className="w-3.5 h-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          )}
                          <span className="capitalize">{update.type}</span>
                        </div>
                      </div>
                      <div>{update.summary}</div>
                    </div>
                  ))}
                </div>
              )}

              {/* Slack button inside blue box when there are updates */}
              <Button 
                color="white" 
                className="w-full justify-center py-2.5"
                onClick={handleSlackOpen}
              >
                <span className="flex items-center gap-2">
                  <img 
                    src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/slack.png"
                    alt="Slack"
                    className="h-4 w-4 object-contain"
                  />
                  Open in Slack
                </span>
              </Button>
            </div>
          </section>
        )}

        {/* Standalone Slack button when no updates */}
        {!channelInfo?.updates?.has_updates && (
          <Button 
            color="white" 
            className="w-full justify-center py-2.5"
            onClick={handleSlackOpen}
          >
            <span className="flex items-center gap-2">
              <img 
                src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/slack.png"
                alt="Slack"
                className="h-4 w-4 object-contain"
              />
              Open in Slack
            </span>
          </Button>
        )}

        {/* Messages Section */}
        <div className={clsx(
          "transition-all duration-500 delay-300",
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        )}>
          {channelInfo?.messages?.length ? (
            channelInfo.messages.map((message) => (
              <section 
                key={message.timestamp}
                onClick={() => handleMessageClick(message)}
                className="bg-white dark:bg-gray-900 rounded-lg shadow-sm ring-1 ring-zinc-950/5 dark:ring-white/10 mb-4 last:mb-0"
              >
                <div>
                  <div className="px-4 py-4 flex items-center justify-between">
                    <div className="flex items-center min-w-0 gap-2 flex-1">
                      <span className="font-medium text-sm text-zinc-900 dark:text-white flex-shrink-0">
                        {message.name || message.user}
                      </span>
                      {message.thread_messages && message.thread_messages.length > 0 && (
                        <span className="text-sm truncate text-zinc-600 dark:text-zinc-300 min-w-0">
                          {message.thread_messages.length} {message.thread_messages.length === 1 ? 'Reply' : 'Replies'}
                        </span>
                      )}
                    </div>
                    <span className="text-xs text-zinc-500">
                      {new Date(Number(message.timestamp.split('.')[0]) * 1000).toLocaleDateString(undefined, {
                        month: 'numeric',
                        day: 'numeric'
                      })} {new Date(Number(message.timestamp.split('.')[0]) * 1000).toLocaleTimeString([], { 
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true 
                      })}
                    </span>
                  </div>

                  <div className="border-t border-zinc-100 dark:border-gray-700" />

                  <div className="px-4 py-4">
                    <div className="text-sm text-zinc-700 dark:text-zinc-300">
                      {message.text}
                    </div>

                    {/* Always show thread messages if they exist */}
                    {message.thread_messages && message.thread_messages.length > 0 && (
                      <div className="mt-2 pl-4 border-l-2 border-zinc-200 dark:border-zinc-700">
                        {message.thread_messages.map((reply) => (
                          <div 
                            key={reply.timestamp} 
                            className={clsx(
                              "mb-2",
                              reply.is_new_message && "bg-blue-50 dark:bg-blue-900/20 p-2 rounded-lg"
                            )}
                          >
                            <div className="flex items-center justify-between">
                              <span className="font-medium text-xs text-zinc-900 dark:text-white">
                                {reply.name || reply.user}
                                {reply.is_new_message && (
                                  <span className="ml-2 text-xs text-blue-600 dark:text-blue-400">New</span>
                                )}
                              </span>
                              <span className="text-xs text-zinc-500">
                                {new Date(Number(reply.timestamp.split('.')[0]) * 1000).toLocaleDateString(undefined, {
                                  month: 'numeric',
                                  day: 'numeric'
                                })} {new Date(Number(reply.timestamp.split('.')[0]) * 1000).toLocaleTimeString([], { 
                                  hour: 'numeric',
                                  minute: '2-digit',
                                  hour12: true 
                                })}
                              </span>
                            </div>
                            <div className="text-sm text-zinc-700 dark:text-zinc-300">
                              {reply.text}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </section>
            ))
          ) : (
            <div className="text-sm text-zinc-500 dark:text-zinc-400 mt-4 flex justify-center">
              No new messages since your brief!
            </div>
          )}
        </div>

        {/* Link back to brief */}
        {briefId && location.pathname.startsWith('/actions/channel') && (
          <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
            <Link 
              to={`/briefs/${briefId}`}
              className="group flex items-center justify-center w-full py-2.5 text-sm font-bold text-zinc-900 dark:text-zinc-300 bg-white dark:bg-gray-900 hover:bg-zinc-50 dark:hover:bg-gray-800/50 rounded-lg shadow-sm ring-1 ring-zinc-950/10 dark:ring-white/10 transition-colors"
            >
              <svg 
                className="mr-2 h-4 w-4 transform rotate-180 transition-transform group-hover:-translate-x-1" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M17 8l4 4m0 0l-4 4m4-4H3" 
                />
              </svg>
              Go to your daily brief
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
