import React, { useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '../../components/alert';
import { Button } from '../../components/button';
import { Table, TableBody, TableCell, TableRow, TableHead, TableHeader } from '../../components/table';
import { ArrowPathIcon, TrashIcon, XMarkIcon, CheckIcon } from '@heroicons/react/24/solid';
import { useSupabaseClient } from '../../context/supabaseContext';
import { useUser } from '@clerk/clerk-react';
import { 
  DocumentTextIcon, 
  UserGroupIcon, 
  ArrowsPointingOutIcon,
  ChartBarIcon 
} from '@heroicons/react/24/solid';

interface InboxRule {
  rule_name: string;
  rule_type: string;
  confidence: number;
  exceptions: {
    reason: string;
    condition: string;
  }[];
  example_matches: string[];
  rule_explanation: string;
}

interface RuleRow {
  id: number;
  ai_rule_summary: InboxRule;
  user_id: string;
}

interface ManageAIProps {
  open: boolean;
  onClose: () => void;
}

const getRuleTypeIcon = (type: string) => {
  switch (type.toLowerCase()) {
    case 'content':
      return <DocumentTextIcon className="w-3.5 h-3.5" />;
    case 'participant':
      return <UserGroupIcon className="w-3.5 h-3.5" />;
    case 'context':
      return <ArrowsPointingOutIcon className="w-3.5 h-3.5" />;
    case 'pattern':
      return <ChartBarIcon className="w-3.5 h-3.5" />;
    default:
      return null;
  }
};

export const ManageAI: React.FC<ManageAIProps> = ({ open, onClose }) => {
  const { user } = useUser();
  const client = useSupabaseClient();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [rules, setRules] = useState<InboxRule[]>([]);
  const [expandedRules, setExpandedRules] = useState<Set<string>>(new Set());
  const [error, setError] = useState<string | null>(null);
  const [topOfMind, setTopOfMind] = useState<string>('');
  const [draftTopOfMind, setDraftTopOfMind] = useState<string>('');
  const [ruleRows, setRuleRows] = useState<RuleRow[]>([]);
  const [isTopOfMindModified, setIsTopOfMindModified] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    if (open && user && rules.length === 0) {
      loadRules();
      loadTopOfMind();
    }
  }, [open]);

  const loadRules = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const { data: rulesData, error: rulesError } = await client
        .from('user_inbox_rules')
        .select('*')
        .eq('user_id', user?.id);

      if (rulesError) throw rulesError;

      if (!rulesData || rulesData.length === 0) {
        setRules([]);
        setRuleRows([]);
        return;
      }

      const parsedRules = rulesData
        .filter(row => row.ai_rule_summary && 
          typeof row.ai_rule_summary.rule_name === 'string' && 
          typeof row.ai_rule_summary.rule_type === 'string'
        );

      setRuleRows(parsedRules);
      setRules(parsedRules.map(row => row.ai_rule_summary));
    } catch (err) {
      setError('Failed to load rules. Please try again.');
      console.error('Error loading rules:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadTopOfMind = async () => {
    try {
      const { data, error } = await client
        .from('user_settings')
        .select('top_of_mind')
        .eq('user_id', user?.id)
        .single();

      if (error) throw error;
      const value = data?.top_of_mind || '';
      setTopOfMind(value);
      setDraftTopOfMind(value);
    } catch (err) {
      console.error('Error loading top of mind:', err);
    }
  };

  const handleSaveTopOfMind = async () => {
    try {
      setIsSaving(true);
      const { error } = await client
        .from('user_settings')
        .upsert({ 
          user_id: user?.id, 
          top_of_mind: draftTopOfMind 
        });

      if (error) throw error;
      
      setTopOfMind(draftTopOfMind);
      setSaveSuccess(true);
      
      setTimeout(() => {
        setSaveSuccess(false);
        setIsTopOfMindModified(false);
      }, 2000);
      
    } catch (err) {
      console.error('Error saving top of mind:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleTopOfMindChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setDraftTopOfMind(newValue);
    setIsTopOfMindModified(newValue !== topOfMind);
  };

  const handleToggleExpand = (ruleName: string) => {
    setExpandedRules(prev => {
      const newSet = new Set(prev);
      if (newSet.has(ruleName)) {
        newSet.delete(ruleName);
      } else {
        newSet.add(ruleName);
      }
      return newSet;
    });
  };

  const handleSave = async () => {
    if (!user) return;

    try {
      setIsSaving(true);
      setError(null);

      const updates = rules.map(rule => ({
        user_id: user.id,
        ai_rule_summary: rule
      }));

      const { error: deleteError } = await client
        .from('user_inbox_rules')
        .delete()
        .eq('user_id', user.id);

      if (deleteError) throw deleteError;

      const { error: insertError } = await client
        .from('user_inbox_rules')
        .insert(updates);

      if (insertError) throw insertError;

      onClose();
    } catch (err) {
      setError('Failed to save changes. Please try again.');
      console.error('Error saving rules:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteRule = async (ruleName: string) => {
    if (!user) return;
    try {
      setIsSaving(true);
      setError(null);

      const ruleToDelete = ruleRows.find(row => row.ai_rule_summary.rule_name === ruleName);
      if (!ruleToDelete) return;

      const { error: deleteError } = await client
        .from('user_inbox_rules')
        .delete()
        .eq('id', ruleToDelete.id);

      if (deleteError) throw deleteError;

      const filteredRules = rules.filter(rule => rule.rule_name !== ruleName);
      const filteredRows = ruleRows.filter(row => row.id !== ruleToDelete.id);
      setRules(filteredRules);
      setRuleRows(filteredRows);
    } catch (err) {
      setError('Failed to delete rule. Please try again.');
      console.error('Error deleting rule:', err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Alert 
      open={open} 
      onClose={onClose} 
      size="xl"
      className="w-full sm:w-auto md:max-w-4xl"
    >
      <div className="flex justify-between items-start mb-2">
        <div>
          <AlertTitle className="mb-0.5">Personalize AI</AlertTitle>
          <AlertDescription className="text-sm text-gray-500 dark:text-gray-400">
            Add instructions and rules for how the AI analyzes your data
          </AlertDescription>
        </div>
        <Button
          plain
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
        >
          <XMarkIcon className="h-5 w-5" />
        </Button>
      </div>

      <div className="mt-1 space-y-6 pb-6">
        {/* Top of Mind Section */}
        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg px-3 sm:px-6 py-4">
          <div className="space-y-0">
            <h3 className="text-base font-medium text-gray-900 dark:text-gray-100 mb-0.5">Top of Mind</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Share your current goals and focus areas to improve your briefs
            </p>
          </div>
          <textarea
            value={draftTopOfMind}
            onChange={handleTopOfMindChange}
            placeholder="Example: I'm focused on growing revenue this quarter, particularly interested in opportunities from existing customers. I'm also watching our AWS costs closely."
            className="w-full h-32 p-3 text-base leading-relaxed border rounded-md mt-4 
              bg-white dark:bg-gray-900 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500
              placeholder:text-base placeholder:leading-relaxed
              transition-shadow duration-200"
          />
          {isTopOfMindModified && (
            <div className="mt-2 flex justify-end">
              <Button 
                onClick={handleSaveTopOfMind}
                disabled={isSaving || saveSuccess}
                className="flex items-center gap-2 relative"
              >
                {isSaving ? (
                  <>
                    <ArrowPathIcon className="animate-spin h-4 w-4" />
                    <span>Saving...</span>
                  </>
                ) : saveSuccess ? (
                  <>
                    <span>Saved</span>
                    <CheckIcon className="h-4 w-4 text-green-500 ml-1" />
                  </>
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          )}
        </div>

        {/* Email Filtering Rules Section */}
        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg px-3 sm:px-6 py-4">
          <div className="space-y-0">
            <h3 className="text-base font-medium text-gray-900 dark:text-gray-100 mb-0.5">Filtering Rules</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Reduce ongoing noise in your briefs by muting priorities
            </p>
          </div>
          {error && (
            <div className="mb-4 text-red-600 text-sm">{error}</div>
          )}
          {isLoading ? (
            <div className="flex justify-center p-4">
              <ArrowPathIcon className="animate-spin h-5 w-5 text-gray-500" />
            </div>
          ) : rules.length === 0 ? (
            <div className="text-center py-8 text-sm text-gray-500">
              Rules are automatically generated when you mute priorities
            </div>
          ) : (
            <div className="max-h-96 overflow-x-auto overflow-y-auto mt-4 -mx-3 sm:mx-0">
              <div className="min-w-full inline-block align-middle px-2 sm:px-0">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader className="pl-6 pr-4 sm:px-4">Exclusion</TableHeader>
                      <TableHeader className="hidden sm:table-cell">Context</TableHeader>
                      <TableHeader className="hidden sm:table-cell"></TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rules.map((rule) => (
                      <React.Fragment key={rule.rule_name}>
                        <TableRow 
                          className="cursor-pointer hover:bg-gray-50 group"
                          onClick={() => handleToggleExpand(rule.rule_name)}
                        >
                          <TableCell className="pl-6 pr-4 sm:px-4">
                            <div className="flex items-center justify-between sm:justify-start gap-2">
                              <div className="flex flex-wrap items-center gap-2">
                                <span className="font-medium">{rule.rule_name}</span>
                                <div className="inline-flex items-center gap-1.5 px-2.5 py-0.5 rounded-full text-xs font-medium bg-zinc-100 dark:bg-gray-700 text-zinc-700 dark:text-zinc-300">
                                  {getRuleTypeIcon(rule.rule_type)}
                                  <span className="capitalize">{rule.rule_type}</span>
                                </div>
                              </div>
                              {/* Mobile delete button */}
                              <Button
                                plain
                                className="sm:hidden opacity-100 text-red-600 hover:text-red-700 hover:bg-red-50 p-1"
                                onClick={(e: React.MouseEvent) => {
                                  e.stopPropagation();
                                  handleDeleteRule(rule.rule_name);
                                }}
                              >
                                <TrashIcon className="h-4 w-4" />
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell className="hidden sm:table-cell px-4 text-sm text-gray-600 whitespace-normal break-words max-w-[400px]">
                            {rule.rule_explanation}
                          </TableCell>
                          <TableCell className="hidden sm:table-cell w-8 px-4">
                            <Button
                              plain
                              className="opacity-0 group-hover:opacity-100 text-red-600 hover:text-red-700 hover:bg-red-50 p-1"
                              onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                handleDeleteRule(rule.rule_name);
                              }}
                            >
                              <TrashIcon className="h-4 w-4" />
                            </Button>
                          </TableCell>
                        </TableRow>
                        {expandedRules.has(rule.rule_name) && (
                          <TableRow className="bg-gray-50">
                            <TableCell colSpan={3} className="px-6 sm:px-16 py-3">
                              {/* Show context on mobile in expanded view */}
                              <div className="block sm:hidden mb-3">
                                <span className="text-sm font-medium text-gray-700">Context:</span>
                                <p className="mt-1 text-sm text-gray-600">{rule.rule_explanation}</p>
                              </div>
                              <div className="space-y-3 text-xs text-gray-500 border-l-2 border-gray-200 pl-4">
                                {rule.exceptions.length > 0 && (
                                  <div>
                                    <span className="text-xs text-gray-500">Exceptions:</span>
                                    <ul className="list-disc list-inside ml-2 mt-1">
                                      {rule.exceptions.map((exception, idx) => (
                                        <li key={idx} className="text-xs text-gray-500 italic pl-2">
                                          {exception.condition}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                {rule.example_matches.length > 0 && (
                                  <div>
                                    <span className="text-xs text-gray-500">Examples:</span>
                                    <ul className="list-disc list-inside ml-2 mt-1">
                                      {rule.example_matches.map((example, idx) => (
                                        <li key={idx} className="text-xs text-gray-500 italic pl-2">
                                          {example}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
        </div>
      </div>
    </Alert>
  );
}; 