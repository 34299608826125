import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DefaultActions } from './action-panes/default';
import { EmailActions } from './action-panes/inbox';
import { ThreadInfo } from '../types/thread';
import { useAuth } from '@clerk/clerk-react';
import { API_URL } from '../config';
import { Spinner } from '../components/spinner';
import { ChannelActions } from './action-panes/channel';
import { ChannelInfo } from '../types/channel';

// Define prop types for each component
type DefaultActionsProps = {
  emailId: string;
};

type EmailActionsProps = {
  threadId: string;
  briefId: string;
  initialThreadInfo: ThreadInfo;
};

type ChannelActionsProps = {
  channelId: string;
  briefId: string;
  initialChannelInfo: ChannelInfo;
};

// Create a map of components with their respective prop types
const PANE_COMPONENTS = {
  'default': {
    component: DefaultActions as React.ComponentType<DefaultActionsProps>,
    type: 'default' as const
  },
  'inbox': {
    component: EmailActions as React.ComponentType<EmailActionsProps>,
    type: 'inbox' as const
  },
  'channel': {
    component: ChannelActions as React.ComponentType<ChannelActionsProps>,
    type: 'channel' as const
  }
} as const;

type PaneType = keyof typeof PANE_COMPONENTS;

export default function ActionPanePreview() {
  const location = useLocation();
  const pathParts = location.pathname.split('/').filter(Boolean);
  const { getToken } = useAuth();
  const [threadInfo, setThreadInfo] = useState<ThreadInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [channelInfo, setChannelInfo] = useState<ChannelInfo | null>(null);
  
  const [paneName = 'default', ...restParts] = pathParts[1]?.split('-') ?? [];
  const paneType = paneName as PaneType;
  const id = restParts.join('-');

  useEffect(() => {
    const fetchInfo = async () => {
      if (!id) return;

      setIsLoading(true);
      try {
        const match = id.match(/([^-]+)-brief-(.+)/);
        if (!match) return;

        const [_, channelOrThreadId, briefId] = match;

        if (paneType === 'inbox') {
          const token = await getToken({ template: "supabase" });
          const response = await fetch(`${API_URL}/api/get-email-thread-info`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ 
              thread_ids: [channelOrThreadId],
              briefId
            })
          });

          const data = await response.json();
          
          if (data.error) {
            throw new Error(data.error);
          }

          const thread = data.threads[0];
          if (thread) {
            const transformedThreadInfo: ThreadInfo = {
              id: thread.thread_id,
              briefId: briefId,
              content: thread.messages.map((msg: any) => msg.body).join('\n\n'),
              messages: thread.messages,
              userEmail: data.user_google_email
            };

            setThreadInfo(transformedThreadInfo);
          }
        } 
        else if (paneType === 'channel') {
          const token = await getToken({ template: "supabase" });
          const response = await fetch(`${API_URL}/api/canvas-slack-channel`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ 
              channel_id: channelOrThreadId,
              brief_id: briefId
            })
          });

          const data = await response.json();
          setChannelInfo(data);
        }
      } catch (error) {
        console.error('Error fetching info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInfo();
  }, [paneType, id, getToken]);

  if ((paneType === 'inbox' || paneType === 'channel') && isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <Spinner className="w-5 h-5" />
      </div>
    );
  }

  const renderComponent = () => {
    if (paneType === 'inbox' && threadInfo) {
      return (
        <EmailActions 
          threadId={threadInfo.id}
          briefId={threadInfo.briefId}
          initialThreadInfo={threadInfo}
        />
      );
    }
    
    if (paneType === 'channel' && channelInfo) {
      const match = id.match(/([^-]+)-brief-(.+)/);
      const briefId = match ? match[2] : '';
      
      return (
        <ChannelActions
          channelId={channelInfo.channel_id}
          briefId={briefId}
          initialChannelInfo={channelInfo}
        />
      );
    }

    const { component: Component } = PANE_COMPONENTS['default'];
    return <Component emailId={id ?? 'test-id'} />;
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 p-2 sm:p-8">
      <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg">
        {renderComponent()}
      </div>
    </div>
  );
}