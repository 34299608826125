import React from 'react';
import { DefaultActions } from '../pages/action-panes/default';
import { EmailActions } from '../pages/action-panes/inbox';
import { ChannelActions } from '../pages/action-panes/channel';
import { ThreadInfo } from '../types/thread';
import { Spinner } from '../components/spinner';

interface DynamicActionPaneProps {
  path: string;
  emailId?: string;
  threadInfo: Record<string, ThreadInfo>;
  channelInfo: Record<string, any>;
  briefDataId?: string;
  isLoading: boolean;
}

export const DynamicActionPane: React.FC<DynamicActionPaneProps> = ({ 
  path, 
  emailId,
  threadInfo,
  channelInfo,
  briefDataId,
  isLoading
}) => {
  const pathParts = path.split('-');
  const type = pathParts[0];
  const id = pathParts[1];
  const briefId = briefDataId;

  console.log('DynamicActionPane Data Flow:', {
    path,
    type,
    id,
    briefId,
    isLoading,
    threadInfoKeys: Object.keys(threadInfo),
    threadData: id ? threadInfo[id] : null,
    threadMessages: id ? threadInfo[id]?.messages : null,
  });

  console.log('DynamicActionPane Render:', {
    path,
    type,
    id,
    briefId,
    threadInfo,
    isLoading,
    hasThreadInfo: id ? !!threadInfo[id] : false
  });

  if (isLoading && (type === 'inbox' || type === 'channel') && id) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <Spinner className="w-5 h-5" />
      </div>
    );
  }

  if (type === 'inbox' && id) {
    if (!threadInfo[id]) {
      return (
        <div className="flex items-center justify-center min-h-[200px]">
          <Spinner className="w-5 h-5" />
        </div>
      );
    }

    return (
      <EmailActions 
        threadId={id} 
        briefId={briefId}
        initialThreadInfo={threadInfo[id]}
      />
    );
  }

  if (type === 'channel' && id) {
    return (
      <ChannelActions 
        channelId={id}
        briefId={briefId}
      />
    );
  }

  return <DefaultActions emailId={emailId} />;
};