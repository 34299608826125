declare global {
    interface Window {
      gtag: (...args: any[]) => void;
    }
  }
  
  // Add test user IDs constant
  const EXCLUDED_USER_IDS = [
    "user_2mtGqmkYlVpg0JvW8yg6ImrR7vP",
    "user_2mtGU4MdaD4OMCWq2O0KyTjoSHy", 
    "user_2nIP7WgIgmI3s9gkJVlRVWYBh9g",
    "user_2nwB4fDLwBazYwACQ5FeSCGrMAx",
    "user_2pigHMDbqOjDlVJiXIgr7005bYI",
    "user_2pippbrZLueJDUSWUMnciDenvdh"
  ];
  
  class Analytics {
    private currentUserId: string | null = null;
  
    setUserId(userId: string | null) {
      this.currentUserId = userId;
    }
  
    private shouldTrack(): boolean {
      const should = !this.currentUserId || !EXCLUDED_USER_IDS.includes(this.currentUserId);
      console.log('Analytics tracking:', {
        userId: this.currentUserId,
        isExcluded: EXCLUDED_USER_IDS.includes(this.currentUserId || ''),
        shouldTrack: should
      });
      return should;
    }
  
    track(eventName: string, params?: Record<string, any>) {
      if (!this.shouldTrack()) return;
      window.gtag('event', eventName, params)
    }
  
    pageView(path: string, title?: string) {
      if (!this.shouldTrack()) return;
      window.gtag('event', 'page_view', {
        page_path: path,
        page_title: title
      })
    }
  }
  
  export default new Analytics()