import React from 'react';
import { SignIn } from "@clerk/clerk-react";

const Login: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8">
        <SignIn 
          signUpUrl="/sign-up" 
          redirectUrl="/briefs"
          appearance={{
            elements: {
              formButtonPrimary: "w-full",
              card: "text-left pt-0",
              headerImage: "hidden",
              logoImage: "hidden",
              socialButtonsIconButton: "hidden",
              header: "gap-0 pt-0 mt-0",
              form: "gap-0",
              headerTitle: "mb-1 mt-0",
              headerSubtitle: "mt-0"
            }
          }}
        />
      </div>
    </div>
  );
};

export default Login;