import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Heading } from '../components/heading';
import { Input } from '../components/input';
import { Button } from '../components/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table';
import { useSupabaseClient } from '../context/supabaseContext';
import { useAuth, useOrganization, useOrganizationList, OrganizationSwitcher, useUser, UserButton, useClerk, SignOutButton, CreateOrganization, SignUp } from '@clerk/clerk-react';
import { API_URL } from '../config';
import { Textarea } from '../components/textarea';
import clsx from 'clsx';
import { EnvelopeIcon, VideoCameraIcon, SpeakerWaveIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { motion, AnimatePresence } from 'framer-motion';
import { Badge } from '../components/badge';
import { SparklesIcon, ArrowPathIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import AutoCompleteInput from '../components/AutoCompleteInput';
import ConfettiExplosion from 'react-confetti-explosion';
import { Link } from 'react-router-dom';
import { useSlack } from '../context/slackContext';
import { useXero } from '../context/xeroContext';
import { useHubspot } from '../context/hubspotContext';
import { useShopify } from '../context/shopifyContext';
import { useGoogle } from '../context/googleContext';
import { TestEmailSection } from '../components/TestEmailSection';
import { HubspotAlert } from './settings-modals/HubspotAlert';
import { GoogleAlert } from './settings-modals/GoogleAlert';
import { ShopifyAlert } from './settings-modals/ShopifyAlert';
import { SlackAlert } from './settings-modals/SlackAlert';
import analytics from '../analytics' 
import TopNavigation from '../components/TopNavigation';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';

const CustomOrganizationSwitcher = (props: React.ComponentProps<typeof OrganizationSwitcher>) => (
  <OrganizationSwitcher 
    {...props}
    appearance={{
      elements: {
        organizationSwitcherTrigger: {
          '&[data-clerk-organization-switcher-trigger]': {
            '&[data-clerk-organization-switcher-trigger-empty]': {
              '&::before': {
                content: '"Please select an organization"',
              },
            },
          },
        },
        // This targets the "Create Organization" option and hides it
        organizationSwitcherPopoverActionButton__createOrganization: {
          display: 'none',
        },
      },
    }}
  />
);

const Tooltip: React.FC<{ text: string; children: React.ReactNode }> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-max max-w-xs p-2 mt-2 text-xs text-white bg-gray-900 rounded-md shadow-lg">
          {text}
        </div>
      )}
    </div>
  );
};

const CustomCreateOrganization = () => (
  <CreateOrganization
    appearance={{
      elements: {
        formFieldInput__slug: {
          display: 'none !important',
        },
        formFieldLabel__slug: {
          display: 'none !important',
        },
        formFieldLabelText__slug: {
          display: 'none !important',
        },
        'formField__slug': {
          display: 'none !important',
        },
        'formFieldRow__slug': {
          display: 'none !important',
        },
        'headerTitle': {
          display: 'none !important',
        },
        formButtonPrimary: {
          backgroundColor: '#2563eb',
          fontWeight: '500',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          padding: '0.5rem 1rem',
          borderRadius: '0.375rem',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
          '&:hover': {
            backgroundColor: '#1d4ed8',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    }}
  />
);

const TEST_USER_IDS = ["user_2mtGqmkYlVpg0JvW8yg6ImrR7vP", "user_2mtGU4MdaD4OMCWq2O0KyTjoSHy", "user_2nIP7WgIgmI3s9gkJVlRVWYBh9g"];

const SettingsNew: React.FC = () => {
  useEffect(() => {
    document.title = 'ABI - Settings';
  }, []);

  const { organization, isLoaded } = useOrganization();
  const [members, setMembers] = useState<any[]>([]);
  const [businessData, setBusinessData] = useState({
    name: '',
    details: ''
  });
  const [initialBusinessData, setInitialBusinessData] = useState({
    name: '',
    details: ''
  });
  const [isBusinessDataModified, setIsBusinessDataModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { user } = useUser();
  const { openUserProfile, openOrganizationProfile } = useClerk();
  const [isUserProfileHovered, setIsUserProfileHovered] = useState(false);

  const client = useSupabaseClient();
  const { getToken } = useAuth();
  const location = useLocation();

  const [dailyReports, setDailyReports] = useState({
    email: true,
    video: false,
    audio: false,
  });

  const [jobData, setJobData] = useState({
    title: '',
    description: '',
    location: '',
  });
  const [initialJobData, setInitialJobData] = useState({
    title: '',
    description: '',
    location: '',
  });
  const [isJobDataModified, setIsJobDataModified] = useState(false);

  const hasOrganization = !!organization?.id;

  const dataSourcesRef = useRef<HTMLElement>(null);

  const [firstEmailSent, setFirstEmailSent] = useState<boolean>(false);
  const [showSetupSuccess, setShowSetupSuccess] = useState<boolean>(false);

  const [userEmail, setUserEmail] = useState<string | null>(null);

  // Add these new state variables at the beginning of your component
  const [isTestEmailSending, setIsTestEmailSending] = useState(false);
  const [testEmailMessage, setTestEmailMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  const [customUserId, setCustomUserId] = useState('');
  const [customEmailRecipient, setCustomEmailRecipient] = useState('');
  const [isCustomTestEmailSending, setIsCustomTestEmailSending] = useState(false);
  const [customTestEmailMessage, setCustomTestEmailMessage] = useState<{ type: string; text: string } | null>(null);

  // Company auto-populate states
  const [isCompanyAutoPopulating, setIsCompanyAutoPopulating] = useState(false);
  const [companyAutoPopulateStatus, setCompanyAutoPopulateStatus] = useState<'idle' | 'success' | 'error'>('idle');

  // Job auto-populate states
  const [isJobAutoPopulating, setIsJobAutoPopulating] = useState(false);
  const [jobAutoPopulateStatus, setJobAutoPopulateStatus] = useState<'idle' | 'success' | 'error'>('idle');


  // Add this state near the top of your component
  const [bulkTestEmailRecipient, setBulkTestEmailRecipient] = useState('');
  const [isBulkTestingSending, setIsBulkTestingSending] = useState(false);
  const [bulkTestResult, setBulkTestResult] = useState<any>(null);

  // Add this near the top of the file with other imports
  const isDevelopment = window.location.hostname === 'localhost';

  // Add these new state variables near the top of the component
  
  const [isSticky, setIsSticky] = useState(false);
    // Add these near the top of the SettingsNew component
  const [isSetupInProgress, setIsSetupInProgress] = useState(false);

  // Add this with the other utility functions
  const setMessage = (message: string) => {
    // Implement message display logic if needed
    console.log(message);
  };
  
  const [isHubspotInputFocused, setIsHubspotInputFocused] = useState(false);
  const [hubspotApiKey, setHubspotApiKey] = useState('');
  const [hubspotStep, setHubspotStep] = useState<'token' | 'pipeline'>('token')
  const [isHubspotSettingsOpen, setIsHubspotSettingsOpen] = useState(false);
  const [isHubspotAlertOpen, setIsHubspotAlertOpen] = useState(false); 

  const [isShopifyAlertOpen, setIsShopifyAlertOpen] = useState(false);
  const [shopifyApiKey, setShopifyApiKey] = useState('')
  const [isShopifySubdomainFocused, setIsShopifySubdomainFocused] = useState(false);
  const [isShopifyInputFocused, setIsShopifyInputFocused] = useState(false);
  const [isExploding, setIsExploding] = useState(false);

  const { 
    isGoogleConnected,  // Add this to destructuring
    connectedEmail,
    handleConnectGoogle,
    handleDisconnectGoogle,
    googleAnalyticsProperties,
    googleAnalyticsPropertyId,
    currentGAProperty,
    isLoadingProperties,
    isGoogleSettingsAlertOpen,
    setIsGoogleSettingsAlertOpen,
    setGoogleAnalyticsPropertyId,
    handleOpenGoogleSettings,
    confirmGoogleSettings,
  } = useGoogle();

  const { 
    isSlackConnected,
    slackChannels,
    isSlackSettingsOpen,
    setIsSlackSettingsOpen,
    handleConnectSlack,
    handleDisconnectSlack,
    handleChannelToggle
  } = useSlack();

  const { 
    isXeroConnected,
    xeroTenantId,
    handleConnectXero,
    handleDisconnectXero,
  } = useXero();

  const { 
    isHubspotConnected,
    handleConnectHubspot,
    handleDisconnectHubspot,
    hubspotPipelines,
    selectedPipelineId,
    selectedStages,
    isLoadingPipelines,
    setSelectedPipelineId,
    setSelectedStages,
    fetchHubspotPipelines,
    toggleStageVisibility,
    saveHubspotSettings,
    isSourceEnabled,
    setIsSourceEnabled,
  } = useHubspot();

  const { 
    isShopifyConnected,
    shopifySubdomain,
    setShopifySubdomain,
    handleConnectShopify,
    handleDisconnectShopify,
    handleConfirmShopifyConnect,
  } = useShopify();

  // Add near other state declarations
  const [highlightedFields, setHighlightedFields] = useState({
    company: false,
    job: false
  });
  const [isAddingAIContext, setIsAddingAIContext] = useState(false);

  // Add this state to track if auto-population has been attempted
  const [hasAttemptedAutoPopulate, setHasAttemptedAutoPopulate] = useState(false);

  // Modify the useEffect to prevent multiple runs
  useEffect(() => {
    if (isLoaded && organization && !hasAttemptedAutoPopulate) {
      const initializeData = async () => {
        try {
          // First fetch the data
          await Promise.all([
            fetchMembers(),
            syncOrganizationToSupabase(organization),
            fetchBusinessData(),
            fetchJobData()
          ]);

          // Add a longer delay to ensure state updates have propagated
          await new Promise(resolve => setTimeout(resolve, 500));
          
          // Get the latest data directly from the database
          const { data: currentData, error: dataError } = await client
            .from('org_info')
            .select('company_details')
            .eq('organization_id', organization.id)
            .single();

          const { data: currentJobData, error: jobError } = await client
            .from('user_settings')
            .select('job_title, job_description')
            .eq('user_id', user?.id)
            .single();

          if (dataError || jobError) {
            console.error('Error fetching current data:', dataError || jobError);
            return;
          }

          // Only attempt auto-population once and only if fields are actually empty
          setHasAttemptedAutoPopulate(true);
          
          console.log('Current database values:', {
            companyDetails: currentData?.company_details,
            jobTitle: currentJobData?.job_title,
            jobDescription: currentJobData?.job_description
          });

          // Check company details from database
          const hasCompanyDetails = !!currentData?.company_details?.trim();
          console.log('Has company details:', hasCompanyDetails);
          
          if (!hasCompanyDetails) {
            console.log('Auto-populating company info...');
            handleAutoPopulateCompanyInfo(true);
          }

          // Check job fields independently from database
          const hasTitle = !!currentJobData?.job_title?.trim();
          const hasDescription = !!currentJobData?.job_description?.trim();
          
          console.log('Has title:', hasTitle);
          console.log('Has description:', hasDescription);

          if (!hasTitle || !hasDescription) {
            console.log('Auto-populating job info...', {
              skipTitle: hasTitle,
              skipDescription: hasDescription
            });
            handleAutoPopulateJobInfo(true, { skipTitle: hasTitle, skipDescription: hasDescription });
          }
        } catch (error) {
          console.error('Error during initialization:', error);
        }
      };
      
      initializeData();
    }
  }, [isLoaded, organization]); // Only depend on isLoaded and organization

  // Consolidate user-related effects
  useEffect(() => {
    if (user) {
      // Initialize user settings and check for errors
      const initializeUser = async () => {
        await fetchUserSettings();
        
        // Check URL params for errors
        const queryParams = new URLSearchParams(location.search);
        const error = queryParams.get('error');
        if (error) {
          setMessage(`Error: ${error}`);
        }
      };

      initializeUser();
    }
  }, [user, location.search]); // Include location.search as dependency

  // Consolidate connection status effects
  const [showFirstEmailSuccess, setShowFirstEmailSuccess] = useState(false);

  useEffect(() => {
    const checkFullyConnectedState = () => {
      // Only show banner if:
      // 1. Has connected sources but hasn't sent first email yet
      // 2. OR just sent the first email (showFirstEmailSuccess is true)
      if ((hasAnyDataSourceConnected() && !firstEmailSent) || showFirstEmailSuccess) {
        setShowSetupSuccess(true);
      } else {
        setShowSetupSuccess(false);
      }
    };

    checkFullyConnectedState();
  }, [isGoogleConnected, isSlackConnected, isShopifyConnected, isXeroConnected, isHubspotConnected, firstEmailSent, showFirstEmailSuccess]);

  // UI effects
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleShopifyConnect = () => {
    setIsShopifyAlertOpen(true);
    setShopifyApiKey(''); // Reset the API key when opening the alert
  };

  const fetchMembers = async () => {
    if (organization) {
      const memberList = await organization.getMemberships();
      setMembers(memberList.data);
    }
  };

  const syncOrganizationToSupabase = async (org: any) => {
    if (!org || !org.id || !org.name || !user?.id) return;

    try {
      // First try to get existing record
      const { data: existingData, error: fetchError } = await client
        .from('org_info')
        .select('*')
        .eq('organization_id', org.id)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      const orgData = {
        organization_id: org.id,
        company_name: org.name,
        user_id: user.id,
        company_logo_url: org.imageUrl || null
      };

      if (existingData) {
        // Update existing record
        const { error: updateError } = await client
          .from('org_info')
          .update(orgData)
          .eq('organization_id', org.id);

        if (updateError) throw updateError;
      } else {
        // Check if user already has an org
        const { data: userOrg, error: userOrgError } = await client
          .from('org_info')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (userOrgError && userOrgError.code !== 'PGRST116') {
          throw userOrgError;
        }

        if (userOrg) {
          // Update existing user's org record
          const { error: updateError } = await client
            .from('org_info')
            .update(orgData)
            .eq('user_id', user.id);

          if (updateError) throw updateError;
        } else {
          // Insert new record
          const { error: insertError } = await client
            .from('org_info')
            .insert(orgData);

          if (insertError) throw insertError;
        }
      }

      // Refresh business data after syncing
      await fetchBusinessData();
    } catch (error) {
      console.error('Error syncing organization:', error);
    }
  };

  const fetchBusinessData = async () => {
    try {
      if (!organization?.id) return;

      const { data, error } = await client
        .from('org_info')
        .select('company_name, company_details')
        .eq('organization_id', organization.id)
        .single();

      if (error) throw error;

      const fetchedData = {
        name: organization.name || data?.company_name || '',
        details: data?.company_details || ''
      };
      setBusinessData(fetchedData);
      setInitialBusinessData(fetchedData);
    } catch (error) {
      console.error('Error fetching business data:', error);
    }
  };

  const fetchJobData = async () => {
    try {
      const { data, error } = await client
        .from('user_settings')
        .select('job_title, job_description, location')
        .eq('user_id', user?.id)
        .single();

      if (error) {
        console.error('Error fetching job data:', error);
        return;
      }

      if (data) {
        const fetchedData = {
          title: data.job_title || '',
          description: data.job_description || '',
          location: data.location ? data.location.replace(',', ', ') : '' // Display with space
        };
        setJobData(fetchedData);
        setInitialJobData(fetchedData);
      }
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  const fetchUserSettings = async () => {
    if (!user) return;

    try {
      const { data, error } = await client
        .from('user_settings')
        .select('channel_email, channel_video, channel_audio, source_hubspot, first_email_sent, user_email')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setDailyReports({
          email: data.channel_email ?? true,
          video: data.channel_video ?? false,
          audio: data.channel_audio ?? false,
        });
        setIsSourceEnabled(data.source_hubspot ?? false);
        setFirstEmailSent(data.first_email_sent ?? false);
        setUserEmail(data.user_email ?? null);
      } else {
        // If no data is found, set email to true by default
        setDailyReports(prev => ({ ...prev, email: true }));
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      // In case of error, still set email to true by default
      setDailyReports(prev => ({ ...prev, email: true }));
    }
  };

  // Modify the useEffect hook to only show banner when needed
  useEffect(() => {
    if (!firstEmailSent && hasAnyDataSourceConnected()) {
      setShowSetupSuccess(true);
    }
  }, [isGoogleConnected, isSlackConnected, isShopifyConnected, isXeroConnected, isHubspotConnected]);

  // Add new state specifically for email sending
  const [isEmailSending, setIsEmailSending] = useState(false);

  const handleSendFirstEmail = async () => {
    try {
      setIsEmailSending(true);
      setIsExploding(true);
      
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/send-first-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true'
        },
      });

      // Even if we get a 500, check if the email was actually sent
      const { data: emailCheck } = await client
        .from('sent_emails')
        .select('*')
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (emailCheck || response.ok) {
        const { error: updateError } = await client
          .from('user_settings')
          .upsert({
            user_id: user?.id,
            first_email_sent: true,
            channel_email: dailyReports.email,
            channel_video: dailyReports.video,
            channel_audio: dailyReports.audio,
            user_email: user?.primaryEmailAddress?.emailAddress
          })
          .select()
          .single();

        if (updateError) throw updateError;
        
        setFirstEmailSent(true);
        setShowFirstEmailSuccess(true);
        
        // Only hide after delay
      } else {
        throw new Error('Failed to send first email');
      }
    } catch (error) {
      console.error('Error in handleSendFirstEmail:', error);
    } finally {
      setIsEmailSending(false);
      setIsExploding(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setBusinessData(prevData => {
      const newData = { ...prevData, [id]: value };
      setIsBusinessDataModified(JSON.stringify(newData) !== JSON.stringify(initialBusinessData));
      return newData;
    });
  };

  const handleJobInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setJobData(prevData => {
      const newData = { ...prevData, [id]: value };
      setIsJobDataModified(JSON.stringify(newData) !== JSON.stringify(initialJobData));
      return newData;
    });
  };

  const handleLocationSelect = (location: string | null) => {
    setJobData(prevData => {
      const newData = { ...prevData, location: location ? location.replace(',', ', ') : '' };
      setIsJobDataModified(JSON.stringify(newData) !== JSON.stringify(initialJobData));
      return newData;
    });
  };

  const handleSaveSettings = async () => {
    try {
      if (!organization?.id) {
        throw new Error('Organization ID is not available');
      }

      const token = await getToken({ template: "supabase" });
      // First, check if a record exists
      const { data: existingData, error: fetchError } = await client
        .from('org_info')
        .select('*')
        .eq('organization_id', organization.id)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      let result;
      if (existingData) {
        // If record exists, update it
        result = await client
          .from('org_info')
          .update({
            company_name: businessData.name,
            company_details: businessData.details,
          })
          .eq('organization_id', organization.id);
      } else {
        // If record doesn't exist, insert a new one
        result = await client
          .from('org_info')
          .insert({
            organization_id: organization.id,
            company_name: businessData.name,
            company_details: businessData.details,
          });
      }

      if (result.error) throw result.error;

      setInitialBusinessData(businessData);
    } catch (error) {
      console.error('Error saving business data:', error);
      throw error;
    }
  };

  const handleSaveJobSettings = async () => {
    try {
      if (!user?.id) {
        throw new Error('User ID is not available');
      }

      const { error } = await client
        .from('user_settings')
        .upsert({
          user_id: user.id,
          job_title: jobData.title,
          job_description: jobData.description,
          location: jobData.location ? jobData.location.replace(', ', ',') : null
        }, { onConflict: 'user_id' });

      if (error) throw error;

      setInitialJobData(jobData);
    } catch (error) {
      console.error('Error saving job data:', error);
      throw error;
    }
  };

  const handleDailyReportToggle = async (method: 'email' | 'video' | 'audio') => {
    if (!isGoogleConnected || !user || !user.id) return;

    const newValue = !dailyReports[method];
    setDailyReports(prev => ({ ...prev, [method]: newValue }));

    analytics.track('toggle_daily_report', {
      method,
      enabled: newValue
    });

    try {
      const { error } = await client
        .from('user_settings')
        .upsert(
          {
            user_id: user.id,
            [`channel_${method}`]: newValue,
          },
          { onConflict: 'user_id' }
        );

      if (error) throw error;
    } catch (error) {
      // Revert the state if the update failed
      setDailyReports(prev => ({ ...prev, [method]: !newValue }));
      console.error(`Error updating ${method} setting:`, error);
    }
  };
  
  // Hubspot
  const handleHubspotConnect = () => {
    setIsHubspotAlertOpen(true);
    setHubspotApiKey(''); // Reset the API key when opening the alert
  };

  const handleConfirmHubspotConnect = async () => {
    try {
      // First connect Hubspot
      await handleConnectHubspot(hubspotApiKey);
      
      // After successful connection, fetch pipelines
      await fetchHubspotPipelines();
      
      // Move to pipeline configuration step
      setHubspotStep('pipeline');
      
    } catch (error) {
      console.error('Error connecting Hubspot:', error);
      alert('Failed to connect Hubspot. Please check your API key and try again.');
      // Keep the alert open but reset the API key
      setHubspotApiKey('');
    }
  };

  const handleHubspotSettings = async () => {
    setIsHubspotSettingsOpen(true);
    try {
      await fetchHubspotPipelines();
    } catch (error) {
      console.error('Error loading Hubspot settings:', error);
      alert('Failed to load Hubspot settings. Please try again.');
      setIsHubspotSettingsOpen(false);
    }
  };

  const handleSaveHubspotSettings = async () => {
    try {
      await saveHubspotSettings();
      setIsHubspotSettingsOpen(false);
      setIsHubspotAlertOpen(false);
    } catch (error) {
      alert('Failed to save pipeline configuration. Please try again.');
    }
  };

  // Auto-populate job and org info
  const handleAutoPopulateCompanyInfo = async (silent = false) => {
    if (!user?.id) return;

    setIsCompanyAutoPopulating(true);
    setCompanyAutoPopulateStatus('idle');

    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/auto-populate-company-info`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'  // Add this
        },
        body: JSON.stringify({ user_id: user.id })
      });

      console.log('Response from auto-populate-company-info:', response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Parsed data:', data);

      if (data.error) {
        throw new Error(data.error);
      }

      if (data.summary) {
        setBusinessData(prevData => ({
          ...prevData,
          details: data.summary
        }));
        setCompanyAutoPopulateStatus('success');
        setIsBusinessDataModified(true);
        setIsAddingAIContext(true);
        setHighlightedFields(prev => ({ ...prev, company: true }));
      } else {
        throw new Error('No summary received from the server');
      }
    } catch (error) {
      console.error('Error auto-populating company info:', error);
      setCompanyAutoPopulateStatus('error');
    } finally {
      setIsCompanyAutoPopulating(false);
      setTimeout(() => setCompanyAutoPopulateStatus('idle'), 2000);
    }
  };

  // Modify handleAutoPopulateJobInfo to accept field-specific flags
  const handleAutoPopulateJobInfo = async (silent = false, options = { skipTitle: false, skipDescription: false }) => {
    if (!user?.id) return;

    if (!silent) setIsJobAutoPopulating(true);
    setJobAutoPopulateStatus('idle');

    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/auto-populate-job-info`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
        body: JSON.stringify({ user_id: user.id })
      });

      if (!response.ok) throw new Error('Failed to auto-populate job info');
      const data = await response.json();

      if (data.job_info?.job_title || data.job_info?.job_description) {
        setJobData(prevData => ({
          ...prevData,
          // Only update fields that aren't being skipped
          title: options.skipTitle ? prevData.title : (data.job_info.job_title || prevData.title),
          description: options.skipDescription ? prevData.description : (data.job_info.job_description || prevData.description)
        }));
        setJobAutoPopulateStatus('success');
        setIsJobDataModified(true);
        setIsAddingAIContext(true);
        setHighlightedFields(prev => ({ ...prev, job: true }));
      }
    } catch (error) {
      console.error('Error auto-populating job info:', error);
      setJobAutoPopulateStatus('error');
    } finally {
      if (!silent) setIsJobAutoPopulating(false);
      setTimeout(() => setJobAutoPopulateStatus('idle'), 2000);
    }
  };

  const handleInvite = () => {
    if (organization) {
      openOrganizationProfile();
    }
  };

  const handleConnectClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (dataSourcesRef.current) {
      dataSourcesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Add this state near other test email states
  const [isThreadInfoFetching, setIsThreadInfoFetching] = useState(false);

  // Add this function near other test email functions
  const handleTestThreadInfo = async () => {
    if (!user?.id) return;
    
    setIsThreadInfoFetching(true);
    try {
      console.log('Starting thread info fetch request...');
      
      const token = await getToken({ template: "supabase" });
      console.log('Got auth token, preparing request...');
      
      const requestBody = {
        thread_id: ["1934aef71cdfe4d7"],
      };
      console.log('Request body:', requestBody);
      
      const requestUrl = `${API_URL}/api/get-thread-reply-intentions`;
      console.log('Making request to:', requestUrl);
      
      const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
        body: JSON.stringify(requestBody)
      };
      console.log('Request options:', {
        method: requestOptions.method,
        headers: requestOptions.headers,
        body: requestOptions.body
      });

      const response = await fetch(requestUrl, requestOptions);
      console.log('Response status:', response.status);
      console.log('Response status text:', response.statusText);
      
      // Log response headers
      const headers: Record<string, string> = {};
      response.headers.forEach((value, key) => {
        headers[key] = value;
      });
      console.log('Response headers:', headers);

      if (!response.ok) {
        // Try to get error details if possible
        let errorDetails = '';
        try {
          const errorData = await response.text();
          errorDetails = errorData;
        } catch (e) {
          errorDetails = 'Could not parse error response';
        }
        
        throw new Error(`HTTP error! status: ${response.status}, statusText: ${response.statusText}, details: ${errorDetails}`);
      }

      const data = await response.json();
      console.log('Successfully parsed response data:', data);
      
      // Log specific parts of the response data
      if (data.threads) {
        console.log('Number of threads received:', data.threads.length);
        data.threads.forEach((thread: any, index: number) => {
          console.log(`Thread ${index + 1} details:`, {
            id: thread.id,
            subject: thread.subject,
            // Add other relevant fields you want to inspect
          });
        });
      }

    } catch (error) {
      console.error('Detailed error in thread info fetch:', {
        error,
        message: error instanceof Error ? error.message : 'Unknown error',
        stack: error instanceof Error ? error.stack : undefined
      });
      
      // Log API_URL for debugging
      console.log('Current API_URL:', API_URL);
      
    } finally {
      setIsThreadInfoFetching(false);
    }
  };

  // Add this function near the top of the component to check if any data source is connected
  const hasAnyDataSourceConnected = () => {
    return isGoogleConnected || 
           isSlackConnected || 
           isShopifyConnected || 
           isXeroConnected || 
           isHubspotConnected;
  };

  // Add this state near other test email states
  const [isControlPanelOpen, setIsControlPanelOpen] = useState(false);

  // Combine the save functions into one
  const handleSaveAll = async () => {
    setIsSaving(true);
    try {
      const savePromises = [];
      
      if (isBusinessDataModified) {
        savePromises.push(handleSaveSettings());
      }
      
      if (isJobDataModified) {
        savePromises.push(handleSaveJobSettings());
      }

      await Promise.all([
        Promise.all(savePromises),
        new Promise(resolve => setTimeout(resolve, 800))
      ]);
      
      setIsSaving(false);
      setSaveSuccess(true);
      setIsAddingAIContext(false);
      setHighlightedFields({ company: false, job: false });
      
      setTimeout(() => {
        setSaveSuccess(false);
        setIsBusinessDataModified(false);
        setIsJobDataModified(false);
      }, 2000);
      
    } catch (error) {
      console.error('Error saving data:', error);
      alert('Failed to save changes. Please try again.');
      setIsSaving(false);
    }
  };

  // Modify the button click handlers
  const handleAutoPopulateCompanyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleAutoPopulateCompanyInfo(false);
  };

  const handleAutoPopulateJobClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleAutoPopulateJobInfo(false);
  };

  // 1. Add this to your component's state declarations
  const [hasInitialized, setHasInitialized] = useState(false);

  // 2. Replace the existing useEffect with this version
  useEffect(() => {
    // Only run once when component mounts and has required data
    if (isLoaded && organization && !hasInitialized) {
      const initializeData = async () => {
        try {
          // First fetch existing data
          await Promise.all([
            fetchMembers(),
            syncOrganizationToSupabase(organization),
            fetchBusinessData(),
            fetchJobData()
          ]);

          await new Promise(resolve => setTimeout(resolve, 500));
          
          const { data: currentData } = await client
            .from('org_info')
            .select('company_details')
            .eq('organization_id', organization.id)
            .single();

          const { data: currentJobData } = await client
            .from('user_settings')
            .select('job_title, job_description')
            .eq('user_id', user?.id)
            .single();

          // Only attempt auto-population if fields are empty
          const hasCompanyDetails = !!currentData?.company_details?.trim();
          if (!hasCompanyDetails) {
            await handleAutoPopulateCompanyInfo(true);
          }

          const hasTitle = !!currentJobData?.job_title?.trim();
          const hasDescription = !!currentJobData?.job_description?.trim();
          if (!hasTitle || !hasDescription) {
            await handleAutoPopulateJobInfo(true, { 
              skipTitle: hasTitle, 
              skipDescription: hasDescription 
            });
          }

          // Mark initialization as complete only after everything is done
          setHasInitialized(true);
        } catch (error) {
          console.error('Error during initialization:', error);
          // Still mark as initialized even if there's an error to prevent retries
          setHasInitialized(true);
        }
      };
      
      initializeData();
    }
  }, []); // Empty dependency array since we check conditions inside

  return (
    <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="px-4 md:px-10 py-4 md:py-6">
        <TopNavigation hasOrganization={hasOrganization} />

        <div className="mt-4 md:mt-6 grid grid-cols-1 lg:grid-cols-2 gap-6 h-full">
          {/* Left column - add flex-col and h-full */}
          <div className="h-full flex flex-col space-y-6">
            {/* Business Information section - add flex-1 */}
            <section className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 relative flex-1">
              { !hasOrganization && (
                <div className="absolute inset-0 bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm z-10 rounded-lg flex flex-col items-center justify-start pt-6">
                  <div className="mb-4 w-[400px]">
                    <h2 className="text-xl font-semibold mb-0.5">Company Profile</h2>
                    <p className="text-sm text-gray-500">Create your company profile to get started</p>
                  </div>
                  <CustomCreateOrganization />
                </div>
              )}
              <div className="flex justify-between items-center mb-6 space-y-0">
                <div>
                  <Heading level={2} className="text-xl font-semibold mb-0.5">Profile</Heading>
                  <p className="text-sm text-gray-500">Enter your company and job details</p>
                </div>
              </div>

              <div className="mb-6">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">Location</label>
                <AutoCompleteInput 
                  onLocationSelect={handleLocationSelect} 
                  initialLocation={jobData.location}
                />
              </div>

              <div className="mb-6">
                <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Job Title</label>
                <Input
                  id="title"
                  value={jobData.title}
                  onChange={handleJobInputChange}
                  placeholder="Enter your job title"
                  disabled={!hasOrganization}
                  className={clsx(
                    highlightedFields.job && "ring-2 ring-blue-500/50 ring-offset-0 border-blue-500/50 shadow-[0_0_0_4px_rgba(59,130,246,0.25)] rounded-md transition-all duration-200",
                    isAddingAIContext && "text-blue-600"
                  )}
                />
              </div>

              <div className="mb-6">
                <label htmlFor="jobDescription" className="block text-sm font-medium text-gray-700 mb-1">Job Description</label>
                <Textarea
                  id="description"
                  value={jobData.description}
                  onChange={handleJobInputChange}
                  placeholder="Describe your role and day-to-day responsibilities"
                  className={clsx(
                    "w-full h-32",
                    highlightedFields.job && "ring-2 ring-blue-500/50 ring-offset-0 border-blue-500/50 shadow-[0_0_0_4px_rgba(59,130,246,0.25)] rounded-md transition-all duration-200",
                    isAddingAIContext && "text-blue-600"
                  )}
                  disabled={!hasOrganization}
                />
              </div>

              <div className="mb-6 relative">
                <label htmlFor="details" className="block text-sm font-medium text-gray-700 mb-1">
                  Company Description
                </label>
                <Textarea 
                  id="details" 
                  value={businessData.details}
                  onChange={handleInputChange}
                  placeholder="Tell us more about your business, strategy, and team structure" 
                  className={clsx(
                    "w-full h-32",
                    highlightedFields.company && "ring-2 ring-blue-500/50 ring-offset-0 border-blue-500/50 shadow-[0_0_0_4px_rgba(59,130,246,0.25)] rounded-md transition-all duration-200",
                    isAddingAIContext && "text-blue-600"
                  )}
                  disabled={!hasOrganization}
                />
              </div>

              <AnimatePresence>
                {(isBusinessDataModified || isJobDataModified) && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="flex items-center space-x-3 overflow-hidden"
                  >
                    <Button 
                      color="blue" 
                      onClick={handleSaveAll}
                      disabled={isSaving || saveSuccess}
                      className="relative"
                    >
                      {isSaving ? 'Saving...' : saveSuccess ? 'Saved' : 'Save'}
                      {saveSuccess && (
                        <CheckIcon className="w-5 h-5 text-green-500 absolute -right-6" />
                      )}
                    </Button>
                    {isAddingAIContext && (
                      <span className="text-blue-500 text-sm flex items-center">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          viewBox="0 0 24 24" 
                          fill="currentColor" 
                          className="w-4 h-4 mr-1.5"
                        >
                          <path 
                            fillRule="evenodd" 
                            d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" 
                            clipRule="evenodd" 
                          />
                        </svg>
                        <span className="text-sm font-medium">Suggested details added!</span>
                      </span>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </section>
          </div>

          {/* Right column - spans 1 column */}
          <div className="h-full flex flex-col space-y-6">
            {/* Daily Briefs section */}
            <section className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 relative">
              <div className="flex justify-between items-center mb-4">
                <div className="space-y-0">
                  <Heading level={2} className="text-xl font-semibold mb-0.5">Daily Briefs</Heading>
                  <p className="text-sm text-gray-500">Configure your email preferences</p>
                </div>
                
                <button
                  onClick={() => handleDailyReportToggle('email')}
                  disabled={!hasAnyDataSourceConnected() || isSetupInProgress}
                  className={clsx(
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 self-center",
                    dailyReports.email ? 'bg-blue-600' : 'bg-gray-200',
                    (!hasAnyDataSourceConnected() || isSetupInProgress) && 'opacity-50 cursor-not-allowed'
                  )}
                >
                  <span className={clsx(
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    dailyReports.email ? 'translate-x-5' : 'translate-x-0'
                  )} />
                </button>
              </div>

              {showSetupSuccess && (
                <div className="mt-4 flex items-center bg-green-50 dark:bg-green-900/30 rounded-lg p-4">
                  <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-600 dark:text-green-400" />
                  <div className="ml-3 flex items-center text-sm text-green-700 dark:text-green-300">
                    {firstEmailSent 
                      ? `Add more data sources any time`
                      : "Add all your data sources and get your first brief "}
                    <Button
                      onClick={firstEmailSent ? undefined : handleSendFirstEmail}
                      disabled={isEmailSending || firstEmailSent}
                      color="green"
                      className={clsx(
                        "ml-2 text-sm inline-flex items-center py-0.5",
                        firstEmailSent && "cursor-default"
                      )}
                    >
                      {isEmailSending ? (
                        <ArrowPathIcon className="h-4 w-4 mr-1 animate-spin" />
                      ) : firstEmailSent ? (
                        <CheckIcon className="h-4 w-4 mr-1" />
                      ) : (
                        <PaperAirplaneIcon className="h-4 w-4 mr-1" />
                      )}
                      {isEmailSending ? 'Sending...' : firstEmailSent ? 'Sent!' : 'Send'}
                    </Button>
                    {isExploding && (
                      <div className="absolute left-1/2 -translate-x-1/2">
                        <ConfettiExplosion
                          force={0.8}
                          duration={3000}
                          particleCount={250}
                          width={1600}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {hasAnyDataSourceConnected() ? (
                dailyReports.email ? (
                  <div className="mt-4 flex items-center bg-blue-50 dark:bg-blue-900/30 rounded-lg p-4">
                    <EnvelopeIcon className="h-5 w-5 flex-shrink-0 text-blue-600 dark:text-blue-400" />
                    <p className="ml-3 text-sm text-blue-700 dark:text-blue-300">
                      {user?.primaryEmailAddress?.emailAddress} will receive a daily brief at 7:00AM
                    </p>
                  </div>
                ) : (
                  <div className="mt-4 flex items-center bg-gray-50 dark:bg-gray-700/30 rounded-lg p-4">
                    <ExclamationTriangleIcon className="h-5 w-5 flex-shrink-0 text-gray-400" />
                    <p className="ml-3 text-sm text-gray-500">
                      You are <b>not receiving</b> daily briefs currently
                    </p>
                  </div>
                )
              ) : (
                <div className="mt-4 flex items-center bg-yellow-50 dark:bg-yellow-900/30 rounded-lg p-4">
                  <ExclamationTriangleIcon className="h-5 w-5 flex-shrink-0 text-yellow-600 dark:text-yellow-400" />
                  <p className="ml-3 text-sm text-yellow-700 dark:text-yellow-300">
                    Connect at least one data source to start receiving daily briefs
                  </p>
                </div>
              )}
            </section>

            {/* Data Sources section */}
            <section
              id="data-sources"
              ref={dataSourcesRef}
              className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 flex-1"
            >
              <div className="space-y-0 mb-6">
                <Heading level={2} className="text-xl font-semibold mb-0.5">Data Sources</Heading>
                <p className="text-sm text-gray-500">Manage your connected data sources</p>
              </div>
              <Table className="bg-white dark:bg-gray-800">
                <TableHead>
                  <TableRow>
                    <TableHeader>Source</TableHeader>
                    <TableHeader>Description</TableHeader>
                    <TableHeader>Status</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center">
                        <img src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/google.png" alt="Google" width={24} height={24} className="mr-2" />
                        Google
                        {!isGoogleConnected && (
                          <Tooltip text="Connection is required for daily briefs">
                            <Badge color="blue" className="ml-2 cursor-help">Recommended</Badge>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>Gmail, Calendar, Analytics</TableCell>
                    <TableCell className="flex items-center space-x-2">
                      <Button 
                        onClick={() => {
                          analytics.track('data_source_action', {
                            source: 'google',
                            action: isGoogleConnected ? 'disconnect' : 'connect'
                          });
                          isGoogleConnected ? handleDisconnectGoogle() : handleConnectGoogle();
                        }}
                        color={isGoogleConnected ? "white" : "blue"}
                        className={clsx(
                          "whitespace-nowrap",
                          isGoogleConnected && "bg-white dark:bg-gray-900 shadow-sm hover:bg-gray-100 dark:hover:bg-gray-800 ring-1 ring-black ring-opacity-5"
                        )}
                        disabled={isSetupInProgress}
                      >
                        {isGoogleConnected ? 'Disconnect' : 'Connect'}
                      </Button>
                      {isGoogleConnected && (
                        <button
                          onClick={handleOpenGoogleSettings}
                          className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                          title="Google Settings"
                        >
                          <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center">
                        <img 
                          src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/slack.png" 
                          alt="Slack" 
                          width={22} 
                          height={22} 
                          className="mr-2" 
                      />
                        Slack
                      </div>
                    </TableCell>
                    <TableCell>Channels, Threads, Messages</TableCell>
                    <TableCell className="flex items-center space-x-2">
                      <Button 
                        onClick={() => {
                          analytics.track('data_source_action', {
                            source: 'slack',
                            action: isSlackConnected ? 'disconnect' : 'connect'
                          });
                          isSlackConnected ? handleDisconnectSlack() : handleConnectSlack();
                        }}
                        color={isSlackConnected ? "white" : "blue"}
                        className={clsx(
                          "whitespace-nowrap",
                          isSlackConnected && "bg-white dark:bg-gray-900 shadow-sm hover:bg-gray-100 dark:hover:bg-gray-800 ring-1 ring-black ring-opacity-5"
                        )}
                      >
                        {isSlackConnected ? 'Disconnect' : 'Connect'}
                      </Button>
                      {isSlackConnected && (
                        <button
                          onClick={() => setIsSlackSettingsOpen(true)}
                          className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                          title="Slack Settings"
                        >
                          <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center">
                        <img src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/shopify.png" alt="Shopify" width={22} height={22} className="mr-2" />
                        Shopify
                      </div>
                    </TableCell>
                    <TableCell>Orders, Customers, Analytics</TableCell>
                    <TableCell className="flex items-center space-x-2">
                      <Button 
                        onClick={() => {
                          analytics.track('data_source_action', {
                            source: 'shopify',
                            action: isShopifyConnected ? 'disconnect' : 'connect'
                          });
                          isShopifyConnected ? handleDisconnectShopify() : handleShopifyConnect();
                        }}
                        color={isShopifyConnected ? "white" : "blue"}
                        className={clsx(
                          "whitespace-nowrap",
                          isShopifyConnected && "bg-white dark:bg-gray-900 shadow-sm hover:bg-gray-100 dark:hover:bg-gray-800 ring-1 ring-black ring-opacity-5"
                        )}
                        disabled={isSetupInProgress}
                      >
                        {isShopifyConnected ? 'Disconnect' : 'Connect'}
                      </Button>
                      {isShopifyConnected && (
                        <button
                          onClick={handleShopifyConnect}
                          className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                          title="Shopify Settings"
                        >
                          <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center">
                        <img src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/xero.png" alt="Xero" width={24} height={24} className="mr-2" />
                        Xero
                      </div>
                    </TableCell>
                    <TableCell>Invoices, Bills, Transactions</TableCell>
                    <TableCell>
                      <Button 
                        onClick={() => {
                          analytics.track('data_source_action', {
                            source: 'xero',
                            action: isXeroConnected ? 'disconnect' : 'connect'
                          });
                          isXeroConnected ? handleDisconnectXero() : handleConnectXero();
                        }}
                        color={isXeroConnected ? "white" : "blue"}
                        className={clsx(
                          "whitespace-nowrap",
                          isXeroConnected && "bg-white dark:bg-gray-900 shadow-sm hover:bg-gray-100 dark:hover:bg-gray-800 ring-1 ring-black ring-opacity-5"
                        )}
                        disabled={isSetupInProgress}
                      >
                        {isXeroConnected ? 'Disconnect' : 'Connect'}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className="flex items-center">
                        <img src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/hubspot.png" alt="Hubspot" width={24} height={24} className="mr-2" />
                        Hubspot
                      </div>
                    </TableCell>
                    <TableCell>Deals, Contacts, Companies</TableCell>
                    <TableCell className="flex items-center space-x-2">
                      <Button 
                        onClick={() => {
                          analytics.track('data_source_action', {
                            source: 'hubspot',
                            action: isHubspotConnected ? 'disconnect' : 'connect'
                          });
                          isHubspotConnected ? handleDisconnectHubspot() : handleHubspotConnect();
                        }}
                        color={isHubspotConnected ? "white" : "blue"}
                        className={clsx(
                          "whitespace-nowrap",
                          isHubspotConnected && "bg-white dark:bg-gray-900 shadow-sm hover:bg-gray-100 dark:hover:bg-gray-800 ring-1 ring-black ring-opacity-5"
                        )}
                        disabled={isSetupInProgress}
                      >
                        {isHubspotConnected ? 'Disconnect' : 'Connect'}
                      </Button>
                      {isHubspotConnected && (
                      <button
                        onClick={handleHubspotSettings}
                        className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                        title="Hubspot Settings"
                      >
                        <Cog6ToothIcon className="h-5 w-5 text-gray-500" />
                      </button>
                    )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </section>
          </div>
        </div>

        {TEST_USER_IDS.includes(user?.id || '') && (
          <>
            <div className="fixed bottom-6 right-6 z-50">
              <button
                onClick={() => setIsControlPanelOpen(prev => !prev)}
                className="p-3 rounded-full bg-white dark:bg-gray-800 shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                title="Developer Controls"
              >
                <Cog6ToothIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>

            <div 
              className={clsx(
                "fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-lg transition-transform duration-300 ease-in-out z-40",
                "transform",
                isControlPanelOpen ? "translate-y-0" : "translate-y-full"
              )}
            >
              <div className="container mx-auto px-4 py-6 space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  <TestEmailSection 
                    userId={user?.id || ''}
                    isDevelopment={isDevelopment}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <HubspotAlert 
        isOpen={isHubspotAlertOpen}
        onClose={() => setIsHubspotAlertOpen(false)}
        mode="connect"
        hubspotStep={hubspotStep}
        setHubspotStep={setHubspotStep}
        isHubspotConnected={isHubspotConnected}
        hubspotApiKey={hubspotApiKey}
        setHubspotApiKey={setHubspotApiKey}
        isHubspotInputFocused={isHubspotInputFocused}
        setIsHubspotInputFocused={setIsHubspotInputFocused}
        isLoadingPipelines={isLoadingPipelines}
        hubspotPipelines={hubspotPipelines}
        selectedPipelineId={selectedPipelineId}
        setSelectedPipelineId={setSelectedPipelineId}
        selectedStages={selectedStages}
        toggleStageVisibility={toggleStageVisibility}
        handleConfirmHubspotConnect={handleConfirmHubspotConnect}
      />

      <ShopifyAlert 
        isOpen={isShopifyAlertOpen}
        onClose={() => setIsShopifyAlertOpen(false)}
        isShopifyConnected={isShopifyConnected}
        shopifyApiKey={shopifyApiKey}
        setShopifyApiKey={setShopifyApiKey}
        shopifySubdomain={shopifySubdomain}
        setShopifySubdomain={setShopifySubdomain}
        isShopifyInputFocused={isShopifyInputFocused}
        setIsShopifyInputFocused={setIsShopifyInputFocused}
        isShopifySubdomainFocused={isShopifySubdomainFocused}
        setIsShopifySubdomainFocused={setIsShopifySubdomainFocused}
        handleConfirmShopifyConnect={handleConfirmShopifyConnect}
      />

      <GoogleAlert 
        isOpen={isGoogleSettingsAlertOpen}
        onClose={() => setIsGoogleSettingsAlertOpen(false)}
        isLoadingProperties={isLoadingProperties}
        googleAnalyticsProperties={googleAnalyticsProperties}
        googleAnalyticsPropertyId={googleAnalyticsPropertyId}
        setGoogleAnalyticsPropertyId={setGoogleAnalyticsPropertyId}
        handleOpenGoogleSettings={handleOpenGoogleSettings}
      />

      <SlackAlert 
        isOpen={isSlackSettingsOpen}
        onClose={() => setIsSlackSettingsOpen(false)}
        slackChannels={slackChannels}
        handleChannelToggle={handleChannelToggle}
      />

      <HubspotAlert 
        isOpen={isHubspotSettingsOpen}
        onClose={() => setIsHubspotSettingsOpen(false)}
        mode="settings"
        isLoadingPipelines={isLoadingPipelines}
        hubspotPipelines={hubspotPipelines}
        selectedPipelineId={selectedPipelineId}
        setSelectedPipelineId={setSelectedPipelineId}
        selectedStages={selectedStages}
        toggleStageVisibility={toggleStageVisibility}
        handleSaveHubspotSettings={handleSaveHubspotSettings}
      />
    </div>
  );
};

export default SettingsNew;
