import '../styles/calendar.css';
import { Calendar } from 'react-calendar';
import { format } from 'date-fns';
import { useState } from 'react';
import clsx from 'clsx';
import { Popover } from '@headlessui/react';
import { CalendarIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

interface Email {
  id: string;
  brief_id: string;
  resend_email_id: string;
  created_at: string;
  html: string;
}

interface EmailDatePickerProps {
  emails: Email[];
  selectedEmail: Email | null | undefined;
  onSelectEmail: (email: Email) => void;
  className?: string;
}

export function EmailDatePicker({ emails, selectedEmail, onSelectEmail, className }: EmailDatePickerProps) {
  const [showEmailList, setShowEmailList] = useState(false);
  
  // Create a map of dates to emails, ensuring UTC consistency
  const emailsByDate = emails.reduce((acc, email) => {
    const date = new Date(email.created_at);
    const dateStr = format(date, 'yyyy-MM-dd');
    
    if (!acc[dateStr]) {
      acc[dateStr] = [];
    }
    acc[dateStr].push(email);
    return acc;
  }, {} as Record<string, Email[]>);

  const handleDateClick = (date: Date, close: () => void) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const emailsForDate = emailsByDate[dateStr] || [];
    
    if (emailsForDate.length === 0) {
      return;
    }
    
    if (emailsForDate.length === 1) {
      onSelectEmail(emailsForDate[0]);
      close(); // Close immediately
    } else {
      onSelectEmail(emailsForDate[0]);
      setShowEmailList(true);
    }
  };

  const handleEmailSelect = (email: Email, close: () => void) => {
    onSelectEmail(email);
    setShowEmailList(false);
    close(); // Close the popover after selecting an email
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className={clsx(
            "w-full flex items-center justify-between gap-2 px-3 py-2.5",
            "bg-white dark:bg-gray-700 rounded-lg border dark:border-gray-600",
            "text-sm text-gray-900 dark:text-white",
            "data-[hover]:bg-zinc-950/[2.5%] dark:data-[hover]:bg-white/5",
            "shadow-sm",
            open && "bg-gray-200 dark:bg-gray-700 shadow-inner",
            "[--btn-icon:theme(colors.gray.400)] data-[hover]:[--btn-icon:theme(colors.gray.500)]",
            className
          )}>
            <div className="flex items-center gap-2">
              <CalendarIcon className="w-5 h-5 text-[--btn-icon]" />
              <span className={selectedEmail ? "font-semibold" : ""}>
                {selectedEmail 
                  ? format(new Date(selectedEmail.created_at), 'MMMM d')
                  : 'Select date'
                }
              </span>
            </div>
            <ChevronDownIcon className={clsx(
              "w-5 h-5 text-[--btn-icon] transition-transform",
              open && "transform rotate-180"
            )} />
          </Popover.Button>

          <Popover.Panel className={clsx(
            "absolute z-10 mt-2 w-auto",
            "transition-all duration-200",
            "transform origin-top",
            "min-w-[300px]",
            open ? "opacity-100 scale-100" : "opacity-0 scale-95"
          )}>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              {!showEmailList ? (
                <Calendar
                  value={selectedEmail ? new Date(selectedEmail.created_at) : undefined}
                  onChange={(date) => handleDateClick(date as Date, close)}
                  tileClassName={({ date }) => {
                    const dateStr = format(date, 'yyyy-MM-dd');
                    const hasEmails = emailsByDate[dateStr]?.length > 0;
                    return clsx(
                      'relative',
                      hasEmails ? 'font-bold' : 'text-gray-400 cursor-not-allowed'
                    );
                  }}
                  className={clsx(
                    "p-2",
                    "dark:bg-gray-800",
                    "[&_.react-calendar__tile]:!py-1",
                    "[&_.react-calendar__navigation]:!h-8",
                    "[&_.react-calendar__navigation__label]:!py-1",
                    "[&_.react-calendar__month-view__weekdays]:!py-1",
                    "[&_.react-calendar__tile]:dark:hover:bg-gray-700",
                    "[&_.react-calendar__tile--active]:!bg-blue-500",
                    "[&_.react-calendar__tile--active]:dark:!text-white",
                    "[&_.react-calendar__navigation button]:dark:hover:bg-gray-700",
                    "[&_.react-calendar__month-view__weekdays]:dark:text-gray-400",
                    "[&_button.react-calendar__navigation__prev2-button]:!hidden",
                    "[&_button.react-calendar__navigation__next2-button]:!hidden"
                  )}
                />
              ) : (
                <div className="p-2">
                  <div className="text-sm font-medium text-gray-900 dark:text-white px-3 py-2">
                    Select email for {selectedEmail ? format(new Date(selectedEmail.created_at), 'PPP') : ''}
                  </div>
                  <div className="mt-2 space-y-1">
                    {selectedEmail && emailsByDate[format(new Date(selectedEmail.created_at), 'yyyy-MM-dd')]?.map((email) => (
                      <button
                        key={email.id}
                        onClick={() => handleEmailSelect(email, close)}
                        className={clsx(
                          "w-full px-3 py-2 text-left text-sm rounded-md",
                          "hover:bg-gray-100 dark:hover:bg-gray-700",
                          email.id === selectedEmail.id && "bg-blue-50 dark:bg-blue-900"
                        )}
                      >
                        {format(new Date(email.created_at), 'p')}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}