import React, { useState, useEffect } from 'react';
import cityList from '../components/city.list.json'; // Adjust the path as necessary

interface City {
  id: number;
  name: string;
  country: string;
}

interface AutoCompleteInputProps {
  onLocationSelect: (location: string | null) => void;
  initialLocation: string;
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({ onLocationSelect, initialLocation }) => {
  const [query, setQuery] = useState(initialLocation);
  const [suggestions, setSuggestions] = useState<City[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);

  useEffect(() => {
    setQuery(initialLocation);
  }, [initialLocation]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 2) {
      const filteredCities = (cityList as City[]).filter((city: City) =>
        city.name.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredCities.slice(0, 10)); // Limit to 10 suggestions
      setActiveSuggestionIndex(-1); // Reset active suggestion index
    } else {
      setSuggestions([]);
      if (value === '') {
        onLocationSelect(null); // Trigger null location when input is cleared
      }
    }
  };

  const handleSuggestionClick = (city: City) => {
    const location = `${city.name}, ${city.country}`; // Display with space
    setQuery(location);
    setSuggestions([]);
    onLocationSelect(location.replace(', ', ',')); // Pass the location without space to the parent component
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault(); // Prevent default behavior
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault(); // Prevent default behavior
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter' && activeSuggestionIndex >= 0) {
      e.preventDefault(); // Prevent default behavior
      handleSuggestionClick(suggestions[activeSuggestionIndex]);
    }
  };

  return (
    <div className="autocomplete">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Choose your city"
        className="w-full border border-gray-300 dark:border-gray-600 rounded-md 
          px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing[1.5])-1px)] 
          text-base/6 sm:text-sm/6 
          bg-white dark:bg-gray-800 
          text-gray-900 dark:text-gray-100
          placeholder-gray-500 dark:placeholder-gray-400
          focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
      />
      {suggestions.length > 0 && (
        <ul className="border border-gray-300 dark:border-gray-600 rounded-md mt-1 
          bg-white dark:bg-gray-800 
          shadow-lg">
          {suggestions.map((city, index) => (
            <li
              key={city.id}
              onClick={() => handleSuggestionClick(city)}
              className={`p-2 cursor-pointer 
                text-base/6 sm:text-sm/6
                text-gray-900 dark:text-gray-100
                hover:bg-gray-100 dark:hover:bg-gray-700
                ${index === activeSuggestionIndex ? 'bg-gray-200 dark:bg-gray-600' : ''}`}
            >
              {city.name}, {city.country}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutoCompleteInput;
