import React, { useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { Button } from './button';
import { Input } from './input';
import { Heading } from './heading';
import { API_URL } from '../config';

interface TestEmailSectionProps {
  userId: string;
  isDevelopment: boolean;
}

export const TestEmailSection: React.FC<TestEmailSectionProps> = ({ userId, isDevelopment }) => {
  // Standard test email states
  const [isTestEmailSending, setIsTestEmailSending] = useState(false);
  const [testEmailMessage, setTestEmailMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  
  // Custom test email states
  const [customUserId, setCustomUserId] = useState('');
  const [customEmailRecipient, setCustomEmailRecipient] = useState('');
  const [isCustomTestEmailSending, setIsCustomTestEmailSending] = useState(false);
  const [customTestEmailMessage, setCustomTestEmailMessage] = useState<{ type: string; text: string } | null>(null);
  
  // Bulk test email states
  const [bulkTestEmailRecipient, setBulkTestEmailRecipient] = useState('');
  const [isBulkTestingSending, setIsBulkTestingSending] = useState(false);
  const [bulkTestResult, setBulkTestResult] = useState<any>(null);

  // Add new state
  const [isThreadInfoFetching, setIsThreadInfoFetching] = useState(false);

  // Add new state for exclusions
  const [excludedServices, setExcludedServices] = useState<string[]>([]);

  const { getToken } = useAuth();

  const handleSendTestEmail = async () => {
    setIsTestEmailSending(true);
    setTestEmailMessage(null);
    try {
      const token = await getToken({ template: "supabase" });
      if (!userId) {
        throw new Error('User ID is not available');
      }
      const response = await fetch(`${API_URL}/send-test-email/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true'
        },
        body: JSON.stringify({ test: true })
      });

      if (!response.ok) {
        throw new Error('Failed to send test email');
      }

      setTestEmailMessage({ type: 'success', text: 'Test email sent successfully!' });
    } catch (error) {
      console.error('Error sending test email:', error);
      setTestEmailMessage({ type: 'error', text: 'Failed to send test email. Please try again.' });
    } finally {
      setIsTestEmailSending(false);
    }
  };

  const handleExclusionToggle = (service: string) => {
    setExcludedServices(prev => 
      prev.includes(service) 
        ? prev.filter(s => s !== service)
        : [...prev, service]
    );
  };

  const handleSendCustomTestEmail = async () => {
    setIsCustomTestEmailSending(true);
    setCustomTestEmailMessage(null);
    try {
      const response = await fetch(`${API_URL}/send-test-email/${customUserId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getToken({ template: 'supabase' })}`,
          'ngrok-skip-browser-warning': 'true'
        },
        body: JSON.stringify({ 
          email_recipient: customEmailRecipient,
          exclude: excludedServices.length > 0 ? excludedServices : undefined
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setCustomTestEmailMessage({ type: 'success', text: 'Test email sent successfully!' });
      } else {
        setCustomTestEmailMessage({ type: 'error', text: data.error || 'Failed to send test email' });
      }
    } catch (error) {
      setCustomTestEmailMessage({ type: 'error', text: 'An error occurred while sending the test email' });
    } finally {
      setIsCustomTestEmailSending(false);
    }
  };

  const handleTestAllEmails = async () => {
    try {
      setIsBulkTestingSending(true);
      setBulkTestResult(null);
      
      const response = await fetch(`${API_URL}/api/test-daily-email-system`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getToken({ template: "supabase" })}`,
          'ngrok-skip-browser-warning': 'true'
        },
        body: JSON.stringify({
          recipient_email: bulkTestEmailRecipient || 'relephant.inc@gmail.com'
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to send test emails');
      }

      setBulkTestResult(data.report);
    } catch (error) {
      console.error('Error sending bulk test emails:', error);
      setBulkTestResult({
        error: error instanceof Error ? error.message : 'An error occurred'
      });
    } finally {
      setIsBulkTestingSending(false);
    }
  };

  // Add the handler function
  const handleTestThreadInfo = async () => {
    setIsThreadInfoFetching(true);
    try {
      console.log('Starting thread info fetch request...');
      
      const token = await getToken({ template: "supabase" });
      
      const requestBody = {
        thread_id: ["1934aef71cdfe4d7"],
      };
      
      const response = await fetch(`${API_URL}/api/get-thread-reply-intentions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Successfully parsed response data:', data);

    } catch (error) {
      console.error('Error in thread info fetch:', error);
    } finally {
      setIsThreadInfoFetching(false);
    }
  };

  // Only render for specific user IDs
  if (!["user_2mtGqmkYlVpg0JvW8yg6ImrR7vP", "user_2mtGU4MdaD4OMCWq2O0KyTjoSHy", "user_2nIP7WgIgmI3s9gkJVlRVWYBh9g"].includes(userId)) {
    return null;
  }

  return (
    <>
      <section className="bg-white dark:bg-gray-900 rounded-lg shadow p-6">
        <Heading level={2} className="text-xl font-semibold mb-4">Test Email</Heading>
        {testEmailMessage && (
          <div className={`mb-4 p-2 rounded ${testEmailMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {testEmailMessage.text}
          </div>
        )}
        <Button 
          onClick={handleSendTestEmail} 
          color="dark" 
          disabled={isTestEmailSending}
        >
          {isTestEmailSending ? 'Sending...' : 'Send Test Email'}
        </Button>

        <div className="mt-6">
          <Heading level={3} className="text-lg font-semibold mb-2">Send Test Email for Custom User ID</Heading>
          <div className="flex flex-col space-y-2">
            <Input
              type="text"
              value={customUserId}
              onChange={(e) => setCustomUserId(e.target.value)}
              placeholder="Enter User ID"
              className="flex-grow"
            />
            <Input
              type="email"
              value={customEmailRecipient}
              onChange={(e) => setCustomEmailRecipient(e.target.value)}
              placeholder="Send test to this email (optional)"
              className="flex-grow"
            />
            
            <div className="space-y-2">
              <div className="text-sm text-gray-600">Exclude from test (fewer sections run faster):</div>
              <div className="flex flex-wrap gap-4">
                {['slack', 'shopify', 'hubspot', 'xero', 'google'].map(service => (
                  <label key={service} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={excludedServices.includes(service)}
                      onChange={() => handleExclusionToggle(service)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm capitalize">{service}</span>
                  </label>
                ))}
              </div>
            </div>

            <Button
              onClick={handleSendCustomTestEmail}
              color="dark"
              disabled={isCustomTestEmailSending || !customUserId}
            >
              {isCustomTestEmailSending ? 'Sending...' : 'Send'}
            </Button>
          </div>
          {customTestEmailMessage && (
            <div className={`mt-2 p-2 rounded ${customTestEmailMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
              {customTestEmailMessage.text}
            </div>
          )}
        </div>
      </section>

      {isDevelopment && (
        <section className="bg-white dark:bg-gray-900 rounded-lg shadow p-6 mt-6">
          <Heading level={2} className="text-xl font-semibold mb-4">Test All Emails</Heading>
          
          <div className="flex flex-col space-y-4">
            <Input
              type="email"
              value={bulkTestEmailRecipient}
              onChange={(e) => setBulkTestEmailRecipient(e.target.value)}
              placeholder="Recipient Email (defaults to relephant.inc@gmail.com)"
              className="flex-grow"
            />
            
            <Button 
              onClick={handleTestAllEmails} 
              color="dark" 
              disabled={isBulkTestingSending}
            >
              {isBulkTestingSending ? 'Sending...' : 'Test Sending All Emails'}
            </Button>
          </div>

          {bulkTestResult && (
            <div className="mt-4 p-4 rounded bg-gray-50">
              <h3 className="font-semibold mb-2">Test Results:</h3>
              {bulkTestResult.error ? (
                <div className="text-red-600">{bulkTestResult.error}</div>
              ) : (
                <ul className="space-y-2">
                  <li>Total Eligible Users: {bulkTestResult.total_eligible_users}</li>
                  <li>Successful Sends: {bulkTestResult.successful_sends}</li>
                  <li>Failed Sends: {bulkTestResult.failed_sends}</li>
                  <li>Success Rate: {bulkTestResult.success_rate}</li>
                  {bulkTestResult.failed_user_ids.length > 0 && (
                    <li>
                      Failed User IDs: 
                      <div className="text-sm text-gray-600">
                        {bulkTestResult.failed_user_ids.join(', ')}
                      </div>
                    </li>
                  )}
                </ul>
              )}
            </div>
          )}
        </section>
      )}

      {isDevelopment && (
        <section className="bg-white dark:bg-gray-900 rounded-lg shadow p-6 mt-6">
          <Heading level={2} className="text-xl font-semibold mb-4">Test Thread Info Fetch</Heading>
          <Button
            onClick={handleTestThreadInfo}
            color="dark"
            disabled={isThreadInfoFetching}
          >
            {isThreadInfoFetching ? 'Fetching...' : 'Send test Thread info fetch'}
          </Button>
        </section>
      )}
    </>
  );
}; 