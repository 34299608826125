import React from 'react';
import { Link } from 'react-router-dom';
import { Heading } from '../components/heading';

const MuteSuccess: React.FC = () => {
  return (
    <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="max-w-3xl mx-auto p-6 space-y-6">
        {/* Header */}
        <div className="[&>h1]:!mb-0">
          <Heading level={1} className="text-2xl font-bold text-zinc-900 dark:text-white">
            Muted Priority
          </Heading>
          <p className="text-sm text-zinc-500 dark:text-gray-400">
            You won't receive any more updates about this priority in your daily brief.
          </p>
        </div>

        {/* AI Feedback Box */}
        <section className="bg-blue-50 dark:bg-gray-800 rounded-lg shadow-lg ring-1 ring-blue-100 dark:ring-gray-700 p-6">
          <div className="flex items-center gap-2 text-blue-600 dark:text-blue-400">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="currentColor" 
              className="w-5 h-5"
            >
              <path 
                fillRule="evenodd" 
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" 
                clipRule="evenodd" 
              />
            </svg>
            <span className="text-sm font-medium">
              We've updated your AI model to reflect your preferences.
            </span>
          </div>
        </section>

        {/* Bottom Link */}
        <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
          <Link 
            to="/briefs" 
            className="group flex items-center justify-center w-full py-2.5 text-sm font-bold text-zinc-900 dark:text-zinc-300 bg-white dark:bg-gray-900 hover:bg-zinc-50 dark:hover:bg-gray-800/50 rounded-lg shadow-sm ring-1 ring-zinc-950/10 dark:ring-white/10 transition-colors"
          >
            <svg 
              className="mr-2 h-4 w-4 transform rotate-180 transition-transform group-hover:-translate-x-1" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M17 8l4 4m0 0l-4 4m4-4H3" 
              />
            </svg>
            Go to your daily brief
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MuteSuccess; 