import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useSupabaseClient } from '../context/supabaseContext';
import { useUser, useOrganization, OrganizationSwitcher, UserButton, useClerk, SignOutButton, useAuth } from '@clerk/clerk-react';
import { Heading } from '../components/heading';
import { Select } from '../components/select';
import { Button } from '../components/button';
import clsx from 'clsx';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { DefaultActions } from '../pages/action-panes/default';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { DynamicActionPane } from '../components/DynamicActionPane';
import { ThreadInfo } from '../types/thread';
import { API_URL } from '../config';
import analytics from '../analytics' 
import TopNavigation from '../components/TopNavigation';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { ManageAI } from '../pages/settings-modals/ManageAI';
import { IdentificationIcon } from '@heroicons/react/24/solid';
import { EmailDatePicker } from '../components/EmailDatePicker';
import { Spinner } from '../components/spinner';

interface Email {
  id: string;
  brief_id: string;
  resend_email_id: string;
  created_at: string;
  html: string;
}

const CustomOrganizationSwitcher = (props: React.ComponentProps<typeof OrganizationSwitcher>) => (
    <OrganizationSwitcher 
      {...props}
      appearance={{
        elements: {
          organizationSwitcherTrigger: {
            '&[data-clerk-organization-switcher-trigger]': {
              '&[data-clerk-organization-switcher-trigger-empty]': {
                '&::before': {
                  content: '"Please select an organization"',
                },
              },
            },
          },
          // This targets the "Create Organization" option and hides it
          organizationSwitcherPopoverActionButton__createOrganization: {
            display: 'none',
          },
        },
      }}
    />
  );

const EmailViewer: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [emails, setEmails] = useState<Email[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);
  const client = useSupabaseClient();
  const { user } = useUser();
  const { organization, isLoaded } = useOrganization();
  const { openUserProfile } = useClerk();
  const [isUserProfileHovered, setIsUserProfileHovered] = useState(false);
  const location = useLocation();
  const [isActionPaneOpen, setIsActionPaneOpen] = useState(false);
  const emailContainerRef = useRef<HTMLDivElement>(null);
  const [actionPanePath, setActionPanePath] = useState('default');
  const [threadInfo, setThreadInfo] = useState<Record<string, ThreadInfo>>({});
  const [channelInfo, setChannelInfo] = useState<Record<string, any>>({});
  const [isLoadingThreadInfo, setIsLoadingThreadInfo] = useState(false);
  const [isLoadingChannelInfo, setIsLoadingChannelInfo] = useState(false);
  const { getToken } = useAuth();
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const hasOrganization = !!organization?.id;

  useEffect(() => {
    const fetchEmails = async () => {
      if (emails.length > 0 && selectedEmail) {
        return;
      }

      if (!user) {
        console.log('No user available');
        return;
      }

      try {
        // First get sent emails
        const { data: emailsData, error: emailsError } = await client
          .from('sent_emails')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });

        if (emailsError) throw emailsError;

        // Then get brief data
        const { data: briefsData, error: briefsError } = await client
          .from('daily_brief_input_data')
          .select('*');

        if (briefsError) throw briefsError;

        console.log('Raw emails:', emailsData);
        console.log('Raw briefs:', briefsData);

        // Join the data manually
        const transformedData = emailsData?.map(email => {
          const matchingBrief = briefsData?.find(brief => brief.resend_email_id === email.email_id);
          return {
            id: email.email_id,
            brief_id: matchingBrief?.id.toString() || '',
            resend_email_id: email.email_id,
            created_at: email.created_at,
            html: email.html
          };
        }).filter(email => email.brief_id) || [];

        console.log('Transformed data:', transformedData);
        setEmails(transformedData);
        
        const urlId = id;
        
        if (urlId && transformedData) {
          const emailFromUrl = transformedData.find(e => e.brief_id === urlId);
          
          if (emailFromUrl) {
            setSelectedEmail(emailFromUrl);
          } else {
            console.log('Email not found, navigating to /briefs');
            navigate('/briefs');
          }
        } else if (transformedData && transformedData.length > 0) {
          setSelectedEmail(transformedData[0]);
        }
      } catch (error) {
        console.error('Error fetching emails:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmails();
  }, [user, client, id, navigate, emails.length, selectedEmail]);

  const handleEmailSelect = (emailId: string) => {
    const email = emails.find(e => e.id === emailId);
    if (email) {
      analytics.track('select_email', { 
        email_id: emailId,
        brief_id: email.brief_id,
        date: email.created_at
      });
      setSelectedEmail(email);
      setActionPanePath('default');
      setThreadInfo({});
      navigate(`/briefs/${email.brief_id}`);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useLayoutEffect(() => {
    if (!emailContainerRef.current || !selectedEmail?.html) return;
    
    const container = emailContainerRef.current;
    const briefDataIdMatch = selectedEmail.html?.match(/data-brief-data-id="(\d+)"/);
    const briefDataId = briefDataIdMatch ? briefDataIdMatch[1] : undefined;

    const threadElements = container.querySelectorAll('[data-thread-id]');
    const channelElements = container.querySelectorAll('[data-channel-id]');
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const bgColor = isDarkMode ? 'rgb(30 58 138)' : 'rgb(239 246 255)';
    const hoverColor = isDarkMode ? 'rgb(30 66 159)' : 'rgb(219 234 254)';

    const setupClickableCell = (element: Element, type: 'thread' | 'channel') => {
      const id = element.getAttribute(`data-${type}-id`);
      if (!id) return;

      const firstCell = element.querySelector('td:first-child') as HTMLElement;
      if (!firstCell) return;

      firstCell.style.backgroundColor = bgColor;
      firstCell.style.color = isDarkMode ? 'white' : 'rgb(37 99 235)';
      firstCell.style.fontWeight = '500';
      firstCell.style.cursor = 'pointer';
      firstCell.style.transition = 'background-color 150ms';
      firstCell.style.position = 'relative';

      const sparkle = document.createElement('img');
      sparkle.src = 'https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/sparkles.png';
      sparkle.style.cssText = `
        position: absolute;
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        pointer-events: none;
        display: none;
        opacity: 1;
      `;
      firstCell.appendChild(sparkle);

      firstCell.addEventListener('mouseover', () => {
        firstCell.style.backgroundColor = hoverColor;
        sparkle.style.display = 'block';
      });

      firstCell.addEventListener('mouseout', () => {
        firstCell.style.backgroundColor = bgColor;
        sparkle.style.display = 'none';
      });

      firstCell.addEventListener('click', (e) => {
        e.preventDefault();
        const actionPath = type === 'thread' 
          ? `inbox-${id}-brief-${briefDataId}`
          : `channel-${id}-brief-${briefDataId}`;
        console.log('Setting action path:', actionPath);
        setActionPanePath(actionPath);
        setIsActionPaneOpen(true);

        // Track the sparkles button click
        analytics.track('sparkles_button_click', {
          type,
          id,
          brief_id: briefDataId,
          source: 'brief_table'
        });
      });
    };

    // Setup both types of elements
    threadElements.forEach(el => setupClickableCell(el, 'thread'));
    channelElements.forEach(el => setupClickableCell(el, 'channel'));

    return () => {
      const cells = container.querySelectorAll('td:first-child');
      cells.forEach(cell => {
        (cell as HTMLElement).removeAttribute('style');
        cell.replaceWith(cell.cloneNode(true));
      });
    };
  }, [selectedEmail]);

  useEffect(() => {
    if (selectedEmail) {
      const date = formatDate(selectedEmail.created_at);
      document.title = `ABI - ${date}`;
    } else {
      document.title = 'ABI';
    }
  }, [selectedEmail]);

  useEffect(() => {
    if (!selectedEmail?.html) return;
    
    const container = document.createElement('div');
    container.innerHTML = selectedEmail.html;
    
    const threadElements = container.querySelectorAll('[data-thread-id]');
    const briefDataIdMatch = selectedEmail.html.match(/data-brief-data-id="(\d+)"/);
    const briefId = briefDataIdMatch ? briefDataIdMatch[1] : undefined;

    // Create initial thread info objects
    const newThreadInfo: Record<string, ThreadInfo> = {};
    
    // Collect thread IDs
    const threadIds: string[] = Array.from(threadElements)
      .map(element => element.getAttribute('data-thread-id'))
      .filter((id): id is string => id !== null);

    // Create thread info objects
    threadElements.forEach((element) => {
      const threadId = element.getAttribute('data-thread-id');
      if (threadId) {
        newThreadInfo[threadId] = {
          id: threadId,
          briefId: briefId || '',
          content: element.textContent || '',
        };
      }
    });

    // Set the basic thread info immediately
    setThreadInfo(newThreadInfo);

    // Then fetch detailed thread info
    const fetchThreadInfo = async () => {
      setIsLoadingThreadInfo(true);
      try {
        const token = await getToken({ template: "supabase" });
        const response = await fetch(`${API_URL}/api/get-email-thread-info`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ 
            thread_ids: threadIds,
            briefId,
            skip_intentions: true
          })
        });

        const data = await response.json();
        if (data.error) throw new Error(data.error);
        
        const updatedThreadInfo = { ...newThreadInfo };
        data.threads.forEach((thread: any) => {
          if (updatedThreadInfo[thread.thread_id]) {
            updatedThreadInfo[thread.thread_id] = {
              ...updatedThreadInfo[thread.thread_id],
              id: thread.thread_id,
              messages: thread.messages,
              content: thread.messages.map((msg: any) => msg.body).join('\n\n'),
              userEmail: data.user_google_email
            };
          }
        });

        setThreadInfo(updatedThreadInfo);
      } catch (error) {
        console.error('Error fetching thread info:', error);
      } finally {
        setIsLoadingThreadInfo(false);
      }
    };

    fetchThreadInfo();
  }, [selectedEmail?.id]);

  const briefDataId = selectedEmail?.html?.match(/data-brief-data-id="(\d+)"/)?.[1];
  console.log('Rendering with briefDataId:', briefDataId);

  return (
    <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="px-4 md:px-10 py-4 md:py-6">
        <TopNavigation hasOrganization={hasOrganization} />

        <div className="mt-4 md:mt-6">
          <section className="bg-white dark:bg-gray-800 rounded-lg shadow flex flex-col">
            <div className="p-6 border-b dark:border-gray-700">
              <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                <div className="space-y-0">
                  <Heading level={2} className="text-xl font-semibold dark:text-white mb-0.5">Workspace</Heading>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Read and work on any of your daily briefs</p>
                </div>
                
                {emails.length > 0 && (
                  <div className="flex items-center gap-2 mt-4 md:mt-0 justify-end">
                    <div className="w-2/3 md:w-auto">
                      <EmailDatePicker
                        emails={emails}
                        selectedEmail={selectedEmail}
                        onSelectEmail={(email) => handleEmailSelect(email.id)}
                      />
                    </div>
                    <Button 
                      onClick={() => setIsRulesModalOpen(true)}
                      color="white"
                      className={clsx(
                        "flex items-center gap-2 justify-center whitespace-nowrap",
                        "w-1/3 md:w-auto px-0 md:px-3 md:min-w-[120px] h-10",
                        isRulesModalOpen && "bg-gray-200 dark:bg-gray-700 shadow-inner"
                      )}
                    >
                      <IdentificationIcon className="h-5 w-5 min-w-[20px] min-h-[20px]" />
                      <span className="hidden md:inline">Personalize AI</span>
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-2/3 p-6">
                {isLoading ? (
                  <div className="flex justify-center items-center py-8">
                    <Spinner className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                  </div>
                ) : emails.length > 0 ? (
                  selectedEmail && (
                    <div className="relative">
                      <div 
                        ref={emailContainerRef}
                        className="overflow-auto min-h-[400px] max-h-[calc(100vh-12rem)] relative 
                          [&_*]:!font-sans 
                          [&_p]:!font-sans
                          [&_td]:!font-sans
                          [&_th]:!font-sans
                          [&_div]:!font-sans
                          [&_span]:!font-sans
                          [&_h1]:!font-sans
                          [&_h2]:!font-sans
                          [&_h3]:!font-sans
                          [&_h4]:!font-sans
                          [&_table]:!font-sans
                          [&_*]:![font-family:ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe_UI,Roboto,Helvetica_Neue,Arial,sans-serif]
                          [&_*[style]]:![font-family:ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe_UI,Roboto,Helvetica_Neue,Arial,sans-serif]
                          [&_td>img.logo]:inline-block 
                          [&_td>span]:inline-block 
                          [&_.summary-box]:w-full 
                          [&_.summary-box]:block 
                          [&_.summary-box]:max-w-none 
                          dark:text-gray-100 
                          [&_td]:dark:text-gray-100 
                          [&_th]:!dark:text-gray-900
                          [&_th[style]]:!dark:text-gray-900
                          [&_a]:!dark:text-blue-400
                          [&_a:hover]:!dark:text-blue-300
                          [&_p]:dark:text-gray-100
                          [&_div]:dark:text-gray-100
                          [&_span]:dark:text-gray-100
                          [&_h1]:dark:text-white
                          [&_h2]:dark:text-white
                          [&_h3]:dark:text-white
                          [&_h4]:dark:text-white
                          [&_table]:dark:border-gray-700
                          [&_td]:dark:border-gray-700
                          [&_th[style]]:!dark:bg-gray-700
                          [&_th[style]]:dark:border-gray-600
                          [&_tr]:dark:border-gray-700
                          [&_tr[style*='background-color']]:!dark:bg-gray-700
                          [&_td[style*='background-color']]:!dark:bg-gray-700
                          [&_td[style*='219, 234, 254']]:!dark:bg-blue-900
                          [&_td[data-thread-id]]:!dark:bg-blue-900
                          [&_hr]:dark:border-gray-700
                          dark:[&_.summary-box]:bg-gray-700
                          dark:[&_.summary-box]:border-gray-600
                          [&_th[style*='#f4f4f4']]:!dark:bg-gray-700"
                        style={{ contain: 'paint' }}
                        dangerouslySetInnerHTML={{ 
                          __html: selectedEmail?.html
                            ?.replace(/<img[^>]*(?:sparkles\.png|external-link\.png|mute\.png)[^>]*>/g, '')
                            .replace(/>\s*·\s*/g, '> · ')
                            .replace(/<a[^>]*>[\s\S]*?Open AI actions[\s\S]*?<\/a>/g, '')
                            .replace(/<td[^>]*>\s*<img[^>]*class="logo"[^>]*>[\s\S]*?<\/td>/g, '')
                            .replace(/class="container"/, 'class="container" style="padding: 0 !important"')
                            || ''
                        }}
                      />
                    </div>
                  )
                ) : (
                  <div className="text-center py-8">
                    <p className="text-gray-500 dark:text-gray-400">No daily briefs are available yet, check back soon!</p>
                  </div>
                )}
              </div>

              <div className="w-full md:w-1/3 bg-gray-50 dark:bg-gray-700 border-t md:border-l md:border-t-0 md:rounded-r-lg dark:border-gray-600">
                <div className="min-h-[400px] md:h-[calc(100vh-12rem)] md:overflow-y-auto [&_div>p]:!mt-0">
                  <DynamicActionPane 
                    path={actionPanePath} 
                    emailId={selectedEmail?.id} 
                    threadInfo={threadInfo}
                    channelInfo={channelInfo}
                    briefDataId={briefDataId}
                    isLoading={isLoadingThreadInfo || isLoadingChannelInfo}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <ManageAI 
        open={isRulesModalOpen} 
        onClose={() => setIsRulesModalOpen(false)} 
      />
    </div>
  );
};

export default EmailViewer;
