import React, { useEffect, useState, useRef } from 'react';
import { ThreadInfo } from '../../types/thread';
import { Spinner } from '../../components/spinner';
import { useAuth, useUser } from '@clerk/clerk-react';
import { API_URL } from '../../config';
import clsx from 'clsx';
import { Button } from '../../components/button';
import { useSupabaseClient } from '../../context/supabaseContext';
import { Heading } from '../../components/heading';
import { Link, useLocation } from 'react-router-dom';
import analytics from '../../analytics';
import { MessageCircleOff, MoreVertical, CheckIcon } from 'lucide-react';

interface EmailActionsProps {
  emailId?: string;
  threadId: string;
  briefId?: string;
  initialThreadInfo: ThreadInfo;
}

interface AssessmentData {
  title: string;
  description: string;
}

const Tooltip: React.FC<{ text: string; children: React.ReactNode }> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-max max-w-xs p-2 mt-2 text-xs text-white bg-gray-900 rounded-md shadow-lg">
          {text}
        </div>
      )}
    </div>
  );
};

const parseAssessmentTable = (tableContent: string, threadId: string): AssessmentData | null => {
  console.log('Parsing assessment table:', { tableContent, threadId });
  
  const cleanContent = tableContent
    .replace(/^"|"$/g, '')
    .replace(/\\n/g, '\n')
    .replace(/\\"/g, '"')
    .replace(/\*\*/g, '');
  
  console.log('Cleaned content:', cleanContent);
  
  const rows = cleanContent.split('\n').filter(row => row.includes('|'));
  console.log('Split rows:', rows);
  
  const dataRows = rows.slice(2);
  console.log('Data rows:', dataRows);
  
  const cleanThreadId = threadId.trim().toLowerCase();
  const targetRow = dataRows.find(row => {
    const rowColumns = row.split('|');
    const rowThreadId = rowColumns[rowColumns.length - 2]?.replace(/\s+/g, '').toLowerCase();
    console.log('Comparing thread IDs:', { rowThreadId, cleanThreadId });
    return rowThreadId === cleanThreadId;
  });
  
  console.log('Found target row:', targetRow);
  
  if (!targetRow) {
    console.log('No matching row found for threadId:', cleanThreadId);
    return null;
  }

  const columns = targetRow.split('|')
    .map(col => col.trim())
    .filter(Boolean);
  
  console.log('Parsed columns:', columns);

  if (columns.length < 3) {
    console.log('Not enough columns:', columns);
    return null;
  }

  const result = {
    title: columns[0],
    description: columns[1]
  };
  console.log('Returning assessment data:', result);
  return result;
};

// Add this helper function at the top level
const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }
  return 'unknown';
};

export const EmailActions: React.FC<EmailActionsProps> = ({
  emailId,
  threadId,
  briefId,
  initialThreadInfo
}) => {
  const { user } = useUser();
  const { getToken } = useAuth();
  
  // Initialize with the thread info we already have
  const [threadInfo, setThreadInfo] = useState<ThreadInfo>(() => ({
    ...initialThreadInfo,
    isLoadingIntentions: true,
    isLoadingSuggestion: false,
    intentions: undefined,
    suggestedResponse: undefined
  }));

  // Immediately show thread info from briefs.tsx
  useEffect(() => {
    setThreadInfo(prev => ({
      ...initialThreadInfo,
      isLoadingIntentions: true,
      isLoadingSuggestion: false,
      intentions: prev.intentions,
      suggestedResponse: prev.suggestedResponse
    }));
  }, [initialThreadInfo]);

  // Fetch intentions as soon as component mounts
  useEffect(() => {
    const fetchIntentions = async () => {
      try {
        const token = await getToken({ template: "supabase" });
        console.log('Fetching intentions for thread:', threadId);
        
        const response = await fetch(`${API_URL}/api/get-thread-reply-intentions`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ 
            thread_id: [threadId],
            brief_id: briefId
          })
        });
        
        const data = await response.json();
        console.log('Intentions response:', data);
        
        if (data.error) {
          throw new Error(data.error);
        }

        // Parse the JSON string from reply_intentions
        const parsedIntentions = JSON.parse(data.reply_intentions).intentions;
        
        setThreadInfo(prev => ({
          ...prev,
          intentions: parsedIntentions,
          isLoadingIntentions: false,
          isLoadingSuggestion: true
        }));

        // Chain fetchSuggestion here after successful intentions fetch
        const topIntention = parsedIntentions.find((i: any) => i.rank === 1);
        if (topIntention) {
          try {
            const suggestionResponse = await fetch(`${API_URL}/api/get-thread-suggested-response`, {
              method: 'POST',
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({ 
                thread_id: [threadId],
                brief_id: briefId,
                intention: topIntention.intention
              })
            });
            
            const suggestionData = await suggestionResponse.json();
            
            if (suggestionData.error) {
              throw new Error(suggestionData.error);
            }

            setThreadInfo(prev => ({
              ...prev,
              suggestedResponse: suggestionData.suggested_response,
              isLoadingSuggestion: false
            }));
          } catch (error) {
            console.error('Error fetching suggestion:', error);
            setThreadInfo(prev => ({
              ...prev,
              isLoadingSuggestion: false
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching intentions:', error);
        setThreadInfo(prev => ({
          ...prev,
          isLoadingIntentions: false,
          isLoadingSuggestion: false
        }));
      }
    };

    fetchIntentions();
  }, [threadId, briefId, getToken]);

  const fetchSuggestionForIntention = async (intention: string) => {
    try {
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/api/get-thread-suggested-response`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          thread_id: [threadId],
          brief_id: briefId,
          intention
        })
      });

      const data = await response.json();
      if (data.error) throw new Error(data.error);

      setThreadInfo(prev => ({
        ...prev,
        suggestedResponse: data.suggested_response,
        isLoadingSuggestion: false
      }));
    } catch (error) {
      console.error('Error fetching suggestion:', error);
      setThreadInfo(prev => ({
        ...prev,
        isLoadingSuggestion: false
      }));
    }
  };

  const location = useLocation();

  // Reset UI state when thread changes
  useEffect(() => {
    setExpandedMessages(new Set());
    setIsFullContentVisible(false);
    setSelectedIntention(null);
    setCustomIntention('');
    setSavedCustomIntention('');
    setIsEditingCustomIntention(false);
  }, [threadId]);

  // UI state
  const [expandedMessages, setExpandedMessages] = useState<Set<string>>(new Set());
  const [isFullContentVisible, setIsFullContentVisible] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [gmailSuccess, setGmailSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedIntention, setSelectedIntention] = useState<string | null>(null);
  const [isEditingCustomIntention, setIsEditingCustomIntention] = useState(false);
  const [customIntention, setCustomIntention] = useState('');
  const [savedCustomIntention, setSavedCustomIntention] = useState<string>('');
  const [emailAssessment, setEmailAssessment] = useState<AssessmentData | null>(null);
  const [resendEmailId, setResendEmailId] = useState<string | null>(null);
  const client = useSupabaseClient();

  // Add ref for the container
  const containerRef = useRef<HTMLDivElement>(null);

  // Show transition after loading and scroll on mobile
  useEffect(() => {
    // Only auto-scroll if NOT on the /actions/ route
    const isStandalonePage = location.pathname.startsWith('/actions/');
    
    setTimeout(() => {
      setIsVisible(true);
      // Check if mobile AND not on standalone page
      if (!isStandalonePage && window.innerWidth <= 768 && containerRef.current) {
        const yOffset = containerRef.current.getBoundingClientRect().top + window.scrollY - 20;
        window.scrollTo({
          top: yOffset,
          behavior: 'smooth'
        });
      }
    }, 100);
  }, [location.pathname]);

  // Track intention selection
  const handleIntentionSelect = (intention: string) => {
    if (intention !== selectedIntention) {
      setSelectedIntention(intention);
      setThreadInfo(prev => ({
        ...prev,
        isLoadingSuggestion: true
      }));

      // Ensure threadId is passed as a string, not split into characters
      fetchSuggestionForIntention(intention);
      analytics.track('intention_selected', {
        intention,
        thread_id: threadId,
        brief_id: briefId
      });
    }
  };

  // Track copy action
  const copyToClipboard = async () => {
    if (!threadInfo.suggestedResponse) return;
    try {
      await navigator.clipboard.writeText(threadInfo.suggestedResponse);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
      analytics.track('copy_suggestion', {
        thread_id: threadId,
        brief_id: briefId,
        intention: selectedIntention || threadInfo.intentions?.[0]?.intention
      });
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  // Track Gmail open
  const handleGmailOpen = () => {
    const os = getMobileOperatingSystem();
    analytics.track('open_gmail', {
      thread_id: threadId,
      brief_id: briefId,
      platform: os,
      email_account: threadInfo.userEmail || '0'
    });
    
    const emailAccount = threadInfo.userEmail || '0';
    
    let gmailUrl = `https://mail.google.com/mail/u/${emailAccount}/#inbox/${threadId}`;
    
    if (os === 'iOS') {
      gmailUrl = `googlegmail:///co?messageId=${threadId}`;
      window.location.href = gmailUrl;
    } else if (os === 'Android') {
      gmailUrl = `intent://mail.google.com/mail/u/${emailAccount}/#inbox/${threadId}#Intent;scheme=https;package=com.google.android.gm;end`;
      window.location.href = gmailUrl;
    } else {
      // For web browsers, open in new tab/window
      window.open(gmailUrl, '_blank');
    }
  };

  // Track message expansion
  const toggleMessage = (messageId: string) => {
    setExpandedMessages(prev => {
      const newSet = new Set(prev);
      const willExpand = !newSet.has(messageId);
      
      if (willExpand) {
        analytics.track('expand_message', {
          message_id: messageId,
          thread_id: threadId,
          brief_id: briefId
        });
      }
      
      if (newSet.has(messageId)) {
        newSet.delete(messageId);
      } else {
        newSet.add(messageId);
      }
      return newSet;
    });
  };

  useEffect(() => {
    const fetchEmailAssessment = async () => {
      console.log('Fetching email assessment for briefId:', briefId);
      if (!briefId || !threadId) return;

      try {
        // Determine which ID to use:
        // If briefId contains 'brief-' it's from URL params, otherwise it's from briefDataId
        const queryId = briefId.includes('brief-')
          ? briefId.split('brief-')[1]  // Extract ID from URL format (e.g., 'brief-361' -> '361')
          : briefId;                    // Use briefDataId directly from briefs.tsx

        console.log('Using queryId for assessment lookup:', queryId);

        const { data, error } = await client
          .from('daily_brief_input_data')
          .select('email_assessment, resend_email_id')
          .eq('id', queryId)
          .single();

        console.log('Supabase response:', { data, error });

        if (error) {
          console.error('Supabase error:', error);
          return;
        }

        if (data?.email_assessment) {
          const parsedData = parseAssessmentTable(data.email_assessment, threadId);
          console.log('Parsed assessment data:', parsedData);
          setEmailAssessment(parsedData);
          setResendEmailId(data.resend_email_id);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchEmailAssessment();
  }, [briefId, threadId, client]);

  // Track custom intention creation
  const handleCustomIntentionSave = (intention: string) => {
    if (!intention.trim()) return;
    
    setIsEditingCustomIntention(false);
    setSelectedIntention(intention);
    setSavedCustomIntention(intention);
    setThreadInfo(prev => ({
      ...prev,
      isLoadingSuggestion: true
    }));
    fetchSuggestionForIntention(intention);
    analytics.track('custom_intention_created', {
      intention,
      thread_id: threadId,
      brief_id: briefId
    });
  };

  // Add state at component level
  const [muteSuccess, setMuteSuccess] = useState(false);

  // Update handleMuteThread
  const handleMuteThread = async () => {
    try {
      if (!user?.id) {
        console.error('No user found');
        return;
      }

      console.log('Making mute request with:', { threadId, userId: user.id });
      const token = await getToken({ template: "supabase" });
      const response = await fetch(`${API_URL}/api/mute-thread-authed`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          thread_id: threadId,
          user_id: user.id
        })
      });

      console.log('Mute response:', response.status);

      if (response.ok) {
        const data = await response.json();
        console.log('Thread muted successfully:', data);
        setMuteSuccess(true);
        setIsMuted(true);
        setTimeout(() => {
          setMuteSuccess(false);
          setIsDropdownOpen(false);
        }, 1500);
        
        analytics.track('thread_muted', {
          thread_id: threadId,
          brief_id: briefId
        });
        return;
      }

      const data = await response.json();
      throw new Error(data.error || 'Failed to mute thread');

    } catch (error) {
      console.error('Error muting thread:', error);
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Add this useEffect near your other effects
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isDropdownOpen) {
        const dropdownElement = document.querySelector('[data-dropdown]');
        if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
          setIsDropdownOpen(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isDropdownOpen]);

  // Add new state at component level
  const [isMuted, setIsMuted] = useState(false);

  // Add new useEffect to check muted status
  useEffect(() => {
    const checkMutedStatus = async () => {
      if (!user?.id || !threadId) return;

      try {
        const { data, error } = await client
          .from('user_thread_mute_list')
          .select('*')
          .eq('gmail_thread_id', threadId)
          .eq('user_id', user.id)
          .single();

        // If error code is PGRST116 or no data, thread is not muted
        if (error?.code === 'PGRST116' || !data) {
          setIsMuted(false);
          return;
        }

        if (error) {
          console.error('Error checking muted status:', error);
          return;
        }

        setIsMuted(true);
      } catch (error) {
        console.error('Error checking muted status:', error);
      }
    };

    checkMutedStatus();
  }, [user?.id, threadId, client]);

  // Add unmute handler
  const handleUnmuteThread = async () => {
    try {
      if (!user?.id) {
        console.error('No user found');
        return;
      }

      console.log('Unmuting thread:', { threadId, userId: user.id });
      
      const { error } = await client
        .from('user_thread_mute_list')
        .delete()
        .eq('gmail_thread_id', threadId)
        .eq('user_id', user.id);

      if (error) {
        throw error;
      }

      console.log('Thread unmuted successfully');
      setIsMuted(false);
      setIsDropdownOpen(false);
      
      analytics.track('thread_unmuted', {
        thread_id: threadId,
        brief_id: briefId
      });

    } catch (error) {
      console.error('Error unmuting thread:', error);
    }
  };

  if (!threadInfo.messages) {
    return (
      <div className="flex items-center justify-center min-h-[200px] text-sm text-zinc-500">
        <div className="flex flex-col items-center gap-2">
          <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
          Please try again later.
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-900">
      {isMuted && (
        <div className="bg-red-50 dark:bg-red-900/20 border-b border-red-100 dark:border-red-900/30">
          <div className="max-w-3xl mx-auto px-6 py-2 text-sm text-red-600 dark:text-red-400 flex items-center gap-2">
            <MessageCircleOff className="w-4 h-4" />
            This thread is muted from future briefs
          </div>
        </div>
      )}
      <div 
        ref={containerRef}
        className={clsx(
          "max-w-3xl mx-auto p-6 space-y-6 [&_*]:leading-normal [&_p]:m-0",
          "transition-all duration-500 ease-out",
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        )}
      >
        {/* Header */}
        <div className={clsx(
          "flex justify-between items-start",
          "transition-all duration-500 delay-100",
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        )}>
          {emailAssessment ? (
            <div className="[&>h1]:!mb-0">
              <Heading level={1} className="text-2xl font-bold text-zinc-900 dark:text-white">
                {emailAssessment.title}
              </Heading>
              <p className="text-sm text-zinc-500 dark:text-gray-400">
                {emailAssessment.description}
              </p>
            </div>
          ) : (
            <div className="text-zinc-900 dark:text-white">Priority</div>
          )}
          
          {/* Dropdown Menu */}
          <div className="relative" data-dropdown>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent event bubbling
                console.log('Dropdown button clicked');
                setIsDropdownOpen(!isDropdownOpen);
              }}
              className="p-2 text-zinc-500 hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-zinc-200 rounded-full hover:bg-zinc-100 dark:hover:bg-gray-800"
            >
              <MoreVertical className="w-4 h-4" />
            </button>
            
            {isDropdownOpen && (
              <div className="absolute right-0 mt-1 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log(isMuted ? 'Unmute button clicked' : 'Mute button clicked');
                    isMuted ? handleUnmuteThread() : handleMuteThread();
                  }}
                  className="flex items-center w-full px-4 py-2 text-sm text-zinc-700 dark:text-zinc-200 hover:bg-zinc-100 dark:hover:bg-gray-700"
                >
                  {muteSuccess ? (
                    <>
                      <CheckIcon className="w-4 h-4 mr-2 text-green-500" />
                      Muted!
                    </>
                  ) : (
                    <>
                      <MessageCircleOff className="w-4 h-4 mr-2" />
                      {isMuted ? 'Unmute Priority' : 'Mute Priority'}
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>

        {/* AI Suggestion Section */}
        <section className={clsx(
          "bg-blue-50 dark:bg-gray-800 rounded-lg shadow-lg ring-1 ring-blue-100 dark:ring-gray-700 p-6",
          "transition-all duration-500 delay-200",
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        )}>
          <div className="space-y-4">
            <div className="flex items-center gap-2 text-blue-600 dark:text-blue-400">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clipRule="evenodd" />
              </svg>
              <span className="text-sm font-medium">Suggested Replies</span>
            </div>

            {/* Intentions Section */}
            <div>
              {threadInfo.isLoadingIntentions ? (
                <div className="flex justify-center py-2">
                  <Spinner className="w-4 h-4" />
                </div>
              ) : threadInfo.intentions ? (
                <div className="flex flex-wrap gap-2">
                  {threadInfo.intentions.map((intention, idx) => (
                    <Tooltip key={idx} text={intention.likelihood || ''}>
                      <div
                        className={clsx(
                          "px-2.5 py-0.5 rounded-full text-xs font-medium transition-colors cursor-pointer",
                          (intention.rank === 1 && !selectedIntention) || intention.intention === selectedIntention
                            ? "bg-blue-500 text-white hover:bg-blue-600"
                            : "bg-blue-100 text-blue-800 hover:bg-blue-200"
                        )}
                        onClick={() => handleIntentionSelect(intention.intention)}
                      >
                        {intention.intention}
                      </div>
                    </Tooltip>
                  ))}
                  {/* Custom Intention Pill */}
                  {isEditingCustomIntention ? (
                    <input
                      type="text"
                      autoFocus
                      className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 
                                 border border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={customIntention}
                      onChange={(e) => setCustomIntention(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && customIntention.trim()) {
                          handleCustomIntentionSave(customIntention);
                        } else if (e.key === 'Escape') {
                          setIsEditingCustomIntention(false);
                          setCustomIntention(savedCustomIntention);
                        }
                      }}
                      onBlur={() => {
                        if (customIntention.trim()) {
                          handleCustomIntentionSave(customIntention);
                        } else {
                          setIsEditingCustomIntention(false);
                          setCustomIntention(savedCustomIntention);
                        }
                      }}
                    />
                  ) : (
                    <div
                      className={clsx(
                        "px-2.5 py-0.5 rounded-full text-xs font-medium transition-colors cursor-pointer",
                        savedCustomIntention && savedCustomIntention === selectedIntention
                          ? "bg-blue-500 text-white hover:bg-blue-600"
                          : "bg-blue-100 text-blue-800 hover:bg-blue-200"
                      )}
                      onClick={() => {
                        setIsEditingCustomIntention(true);
                        setCustomIntention(savedCustomIntention || '');
                      }}
                    >
                      {savedCustomIntention || '+ Custom Prompt'}
                    </div>
                  )}
                </div>
              ) : (
                <p className="text-sm text-gray-500">No intentions found</p>
              )}
            </div>

            {/* Suggested Response Section */}
            <div>
              {threadInfo.isLoadingSuggestion ? (
                <div className="flex justify-center py-2">
                  <Spinner className="w-4 h-4" />
                </div>
              ) : threadInfo.suggestedResponse ? (
                <div className="text-sm text-zinc-700 dark:text-zinc-300 whitespace-pre-wrap bg-white dark:bg-gray-800 p-3 rounded-lg shadow-sm ring-1 ring-zinc-950/5 dark:ring-white/10">
                  {threadInfo.suggestedResponse}
                </div>
              ) : (
                <p className="text-sm text-gray-500">No suggestion available</p>
              )}
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col">
              <Button
                color="blue"
                className={clsx(
                  "w-full justify-center py-2.5 transition-all duration-200",
                  copySuccess && "bg-green-600 hover:bg-green-700"
                )}
                onClick={copyToClipboard}
                disabled={!threadInfo.suggestedResponse}
              >
                <span className="flex items-center gap-2">
                  {copySuccess ? "Copied!" : "Copy Reply"}
                </span>
              </Button>

              <Button 
                color="white" 
                className="w-full justify-center py-2.5 mt-2.5"
                onClick={handleGmailOpen}
              >
                <span className="flex items-center gap-2">
                  <img 
                    src="https://wmrxhpmvhwcrrgbvdpok.supabase.co/storage/v1/object/public/logos/gmail.png"
                    alt="Gmail"
                    className="h-4 w-4 object-contain"
                  />
                  Open in Gmail
                </span>
              </Button>
            </div>
          </div>
        </section>

        {/* Email Thread */}
        <div className={clsx(
          "space-y-1 transition-all duration-500 delay-300",
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        )}>
          {[...threadInfo.messages]
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            .map((message, index, array) => {
              const isLatest = index === 0;
              const isExpanded = isLatest || expandedMessages.has(message.message_id);
              const messageDate = new Date(message.date);

              return (
                <section 
                  key={message.message_id} 
                  className={clsx(
                    "rounded-lg shadow-sm",
                    isLatest 
                      ? "bg-white dark:bg-gray-900 ring-1 ring-zinc-950/5 dark:ring-white/10"
                      : "bg-zinc-50/50 dark:bg-gray-900/50 ml-6 cursor-pointer hover:bg-zinc-100/70 dark:hover:bg-gray-800/70 ring-1 ring-zinc-950/5 dark:ring-white/10"
                  )}
                  onClick={() => !isLatest && toggleMessage(message.message_id)}
                >
                  {/* Header - always visible */}
                  <div className={clsx(
                    "p-4",
                    !isLatest && "text-zinc-500 dark:text-gray-400"
                  )}>
                    {/* Single row flex container for all header content */}
                    <div className="flex items-center justify-between gap-2">
                      {/* Left side with From/Subject */}
                      <div className="flex items-center min-w-0 gap-2 flex-1">
                        <span className={clsx(
                          "font-medium text-sm flex-shrink-0",
                          isLatest ? "text-zinc-900 dark:text-white" : "text-zinc-600 dark:text-zinc-400"
                        )}>
                          {message.from.split('<')[0].trim()}
                        </span>
                        {isLatest && (
                          <span className="text-sm truncate text-zinc-600 dark:text-zinc-300 min-w-0">
                            {message.subject}
                          </span>
                        )}
                      </div>

                      {/* Right side with date and expand icon */}
                      <div className="flex items-center gap-3 flex-shrink-0">
                        <span className="text-xs text-zinc-500">
                          {messageDate.toLocaleDateString(undefined, {
                            month: 'numeric',
                            day: 'numeric'
                          })} {messageDate.toLocaleTimeString([], { 
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true 
                          })}
                        </span>
                        {!isLatest && (
                          <svg 
                            className={clsx(
                              "w-5 h-5 text-zinc-400 transition-transform flex-shrink-0",
                              isExpanded ? "transform rotate-180" : ""
                            )}
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Expanded content */}
                  {isExpanded && (
                    <div className="px-4 pb-4 border-t border-zinc-100 dark:border-gray-700">
                      <div className="pt-4">
                        <div className="text-sm text-zinc-500 leading-normal">
                          <div className="mb-2">
                            <span className="font-medium">To:</span> {message.to}
                          </div>
                          {message.cc && (
                            <div className="mb-2">
                              <span className="font-medium">Cc:</span> {message.cc}
                            </div>
                          )}
                        </div>

                        <div className="pt-2 relative">
                          <div 
                            className={clsx(
                              "text-sm text-zinc-700 dark:text-zinc-300 whitespace-pre-wrap break-words",
                              isLatest && !isFullContentVisible && "max-h-[300px] overflow-hidden"
                            )}
                            dangerouslySetInnerHTML={{ __html: message.body }}
                          />
                          
                          {isLatest && !isFullContentVisible && (
                            <div 
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsFullContentVisible(true);
                              }}
                              className="cursor-pointer absolute bottom-0 left-0 right-0"
                            >
                              <div className="h-24 bg-gradient-to-t from-white dark:from-gray-900 to-transparent" />
                              <div className="absolute bottom-2 left-0 right-0 flex justify-center">
                                <svg 
                                  className="w-5 h-5 text-zinc-400 dark:text-zinc-500"
                                  fill="none" 
                                  viewBox="0 0 24 24" 
                                  stroke="currentColor"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                </svg>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              );
            })}
        </div>

        {briefId && resendEmailId && location.pathname.startsWith('/actions/inbox') && (
          <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
            <Link 
              to={`/briefs/${briefId}`}
              className="group flex items-center justify-center w-full py-2.5 text-sm font-bold text-zinc-900 dark:text-zinc-300 bg-white dark:bg-gray-900 hover:bg-zinc-50 dark:hover:bg-gray-800/50 rounded-lg shadow-sm ring-1 ring-zinc-950/10 dark:ring-white/10 transition-colors"
            >
              <svg 
                className="mr-2 h-4 w-4 transform rotate-180 transition-transform group-hover:-translate-x-1" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M17 8l4 4m0 0l-4 4m4-4H3" 
                />
              </svg>
              Go to your daily brief
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};